@import '../../../sass/imports/variables.scss';

.sign-in {
  .si {
    h2 {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
      margin-top: $spacing-07;
      margin-bottom: $spacing-07;
    }

    &--forget-password {
      text-align: right;
      margin-top: $spacing-05;
    }

    &--sign-up {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.16px;
      text-align: center;
      margin-top: $spacing-05;
      margin-bottom: $spacing-05;
    }
  }
}
