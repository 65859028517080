@import "../../../node_modules/@carbon/grid/scss/grid.scss";

.masonry { /* Masonry container */
  margin-left: -$spacing-03;
  margin-right: -$spacing-03;
  column-gap: 1em;
  column-count: 1;

  @media (min-width: map-get($breakpoints, md)) {
    column-count: 2;
  }

  @media (min-width: map-get($breakpoints, lg)) {
    column-count: 3;
  }

  @media (min-width: map-get($breakpoints, xlg)) {
    column-count: 4;
  }
}

.bx--no-gutter--right {
  @media (max-width: map-get($breakpoints, sm)) {
    &-sm {
      padding-right: 0;
    }
  }

  @media (min-width: map-get($breakpoints, md)) {
    &-md {
      padding-right: 0;
    }
  }

  @media (min-width: map-get($breakpoints, lg)) {
    &-lg {
      padding-right: 0;
    }
  }

  @media (min-width: map-get($breakpoints, xlg)) {
    &-xlg {
      padding-right: 0;
    }
  }
}

.bx--no-gutter--left {
  @media (max-width: map-get($breakpoints, sm)) {
    &-sm {
      padding-left: 0;
    }
  }

  @media (min-width: map-get($breakpoints, md)) {
    &-md {
      padding-left: 0;
    }
  }

  @media (min-width: map-get($breakpoints, lg)) {
    &-lg {
      padding-left: 0;
    }
  }

  @media (min-width: map-get($breakpoints, xlg)) {
    &-xlg {
      padding-left: 0;
    }
  }
}

.item { /* Masonry bricks or child elements */
  display: inline-block;
  width: 100%;
  padding-left: $spacing-03;
  padding-right: $spacing-03;
}

.no-margin {
  margin: 0;
}