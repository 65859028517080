@import '../../../sass/imports/variables.scss';

.new-request {
  margin-top: $spacing-04;
  margin-bottom: $spacing-04;

  @media (min-width: map-get($breakpoints, md)) {
    margin-top: $spacing-07;
    margin-bottom: $spacing-07;
  }

  .nr {
    &.question {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
      margin-top: $spacing-05;
      margin-bottom: $spacing-06;
    }

    &.conclusion {
      font-size: 14px;
      line-height: 18px;
      color: $text-01;
      letter-spacing: 0.16px;

      .bx--label {
        margin-bottom: $spacing-05;
      }
    }

    &.success {
      text-align: center;

      h3 {
        font-size: 20px;
        line-height: 26px;
        margin-top: $spacing-06;
        margin-bottom: $spacing-09;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: $text-01;
        margin-bottom: $spacing-07;
      }
    }

    &.evidences {
      select {
        margin-bottom: $spacing-05;
      }
    }

    &.request-proofs {
      p.title {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: $text-01;
        margin-top: $spacing-05;
        margin-bottom: $spacing-03;
      }
    }

    &.request-proofs-testimonial {
      p.title {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: $text-01;
        margin-top: $spacing-05;
        margin-bottom: $spacing-05;
      }
    }
  }

  p.default {
    color: $text-02;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.32px;

    @media (min-width: map-get($breakpoints, md)) {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.16px;
    }
  }
}
