@import '../../../sass/imports/variables.scss';

.audience-vote {

  margin-bottom: $spacing-09;

  h3,
  h4 {
    font-size: 14px;
    line-height: 18px;
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  h3.question {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
    }
  }

  h4.order-type {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 28px;
      line-height: 36px;
      color: $text-01;
    }
  }

  .av {
    &--box-vote {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-05;

      .bx--checkbox-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &--send {
      margin-top: $spacing-05;
    }
  }
}
