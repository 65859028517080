@import 'variables';

.bx--accordion {
  &__title {
    margin: 0;
    font-family: $font-path;
    color: $text-01;

    @media (min-width: map-get($breakpoints, lg)) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &__heading {
    padding: $spacing-05 0;

    &:hover:before {
      background: transparent;
    }

    &:focus:before {
      border: none;
    }
  }

  &__content {
    padding: 0;

    p {
      font-family: $font-path;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.16px;
      color: $text-01;
    }
  }

  &__item {
    @media (min-width: map-get($breakpoints, lg)) {
      border: none;
    }
  }

  &__item:last-child {
    border-bottom: none;
  }

  &__item--active &__content {
    padding-bottom: $spacing-05;
  }
}
