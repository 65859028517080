.bx--select,
.bx--select-input__wrapper,
.bx--select-input__wrapper select {
  width: 100%;
  max-width: 100%;
}

.bx--select__arrow {
  top: 1.2rem;
}
