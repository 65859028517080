@import '../../../../sass/imports/variables.scss';

.navbar {
  &-fixed-margin {
    height: 72px;
    margin-bottom: $spacing-05;

    @media (min-width: map-get($breakpoints, md)) {
      margin-bottom: $spacing-07;
    }
  }
}
