@import '../../../sass/imports/variables.scss';

.detail-audience {
  margin-bottom: $spacing-07;

  .da {
    &--description {
      margin-bottom: $spacing-05;
    }

    &--steps-box {
      background-color: #f3f3f3;
      padding: 2rem 1rem 1rem 1rem;
      margin-bottom: $spacing-05;

      & .steps-row {
        margin-bottom: $spacing-05;
      }

      & .steps-description {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.16px;
      }
    }

    &--steps {

      p.steps--title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.32px;
        margin-bottom: $spacing-05;
      }
    }
  }

  & p.title {
    margin-bottom: $spacing-05;

    @media (min-width: map-get($breakpoints, md)) {
      font-size: 16px;
      line-height: 22px;
      color: $text-01
    }
  }

  & p.description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: $spacing-05;
  }

  .btn--container {
    margin-top: $spacing-03;
    margin-bottom: $spacing-05;

    @media (max-width: map-get($breakpoints, md)) {
      display: flex;
      justify-content: center;
    }
  }

  .label-status {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding: 4px 8px;
    border-radius: 24px;
    margin-right: $spacing-03;

    &--open {
      color: #A11950;
      background-color: #FFCFE1;
    }

    &--in_voting {
      color: #0058A1;
      background-color: #B3E6FF;
    }

    &--postulatory_stage {
      color: #6E32C9;
      background-color: #E6D6FF
    }

    &--result {
      color: #006161;
      background-color: #92EEEE;
    }
  }

  .text-center {
    text-align: center;
  }

  .label-error {
    margin-top: $spacing-07;
  }
}
