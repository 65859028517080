@import '../../../../sass/imports/variables.scss';

.user-container {
  display: flex;
  justify-content: flex-end;

  .bx--dropdown__wrapper--inline {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .bx--list-box.bx--list-box--inline {
    background: transparent;

    &:hover,
    &.bx--list-box--expanded:hover {
      background: transparent;
    }

    .bx--list-box__field {
      height: 2rem;
    }
  }

  .bx--list-box--inline .bx--list-box__label {
    color: #FFF;
  }

  .bx--list-box__menu-icon {
    svg {
      fill: #FFF;
    }
  }

  .label-login {
    display: flex;
    align-items: center;

    svg {
      margin-right: $spacing-02;
    }
  }
}
