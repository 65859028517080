@import '../../../sass/imports/variables.scss';

.request {
  cursor: pointer;

  &-container {
    padding: $spacing-05;
    margin-bottom: $spacing-05;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  }

  h3 {
    font-size: 14px;
    line-height: 18px;
    color: $text-01;
  }

  & .information {
    & p.icon-description {
      display: flex;
      align-items: center;

      &.inline {
        float: right;
        padding-left: $spacing-02;
      }

      & svg {
        margin-left: $spacing-02;
      }
    }
  }
}
