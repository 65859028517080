@import '../../../sass/imports/variables.scss';

.comment {
  margin-bottom: $spacing-03;

  .c {
    &--container {
      padding: $spacing-03;
      color: $text-01;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }

    &--name {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-top: $spacing-03;
      margin-bottom: $spacing-03;

      @media (min-width: map-get($breakpoints, md)) {
        margin-top: $spacing-05;
        margin-bottom: $spacing-05;
      }
    }

    &--description {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.32px;
      color: $text-01;

      @media (min-width: map-get($breakpoints, md)) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
