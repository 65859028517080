@import '../../../sass/imports/variables.scss';

.loading-container {
  margin-top: $spacing-09;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .lc {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    background-color: $ui-05;
    width: 12px;
    height: 12px;
    content: '';
    border-radius: 50%;

    &-one {
      animation-name: animeBallOne;
    }

    &-two {
      animation-name: animeBallTwo;
      margin: 0 2px;
    }

    &-three {
      animation-name: animeBallOne;
    }
  }
}

@keyframes animeBallOne {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.66);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes animeBallTwo {
  0% {
    transform: scale(0.66);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.66);
  }
}
