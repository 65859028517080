@import url(https://fonts.googleapis.com/css?family=Montserrat:700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap);
.navbar-fixed-margin {
  height: 72px;
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .navbar-fixed-margin {
      margin-bottom: 2rem; } }

.audience-information {
  margin-bottom: 1rem; }
  .audience-information .ai--title {
    margin-bottom: 1rem; }
    @media (min-width: 42rem) {
      .audience-information .ai--title {
        font-size: 16px;
        line-height: 22px;
        color: #171717; } }
  .audience-information .ai--icon-description {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }
    @media (min-width: 42rem) {
      .audience-information .ai--icon-description {
        font-size: 16px;
        line-height: 24px;
        color: #171717; } }
    .audience-information .ai--icon-description.inline {
      float: left;
      padding-right: 1rem;
      margin-bottom: 0.5rem; }
    .audience-information .ai--icon-description svg {
      margin-right: 0.25rem; }

.audience-container {
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .audience-container {
      margin-bottom: 2rem; } }

.audience-header {
  background-color: #313344;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2.5rem; }
  @media (min-width: 42rem) {
    .audience-header {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem; } }
  .audience-header h2 {
    font-size: 20px;
    line-height: 26px; }

.audience-body {
  background-color: #f3f3f3;
  padding: 1rem; }
  @media (min-width: 42rem) {
    .audience-body {
      padding: 2rem; } }
  .audience-body .owner {
    display: flex;
    align-items: center;
    margin-top: -3rem;
    margin-bottom: 0.5rem; }
    .audience-body .owner h3 {
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: #ffffff;
      height: 40px; }
      @media (min-width: 42rem) {
        .audience-body .owner h3 {
          margin-bottom: 1rem;
          font-size: 16px;
          line-height: 24px; } }
  .audience-body p.description {
    margin-bottom: 1rem; }
    @media (min-width: 42rem) {
      .audience-body p.description {
        margin-bottom: 2rem;
        font-size: 16px;
        line-height: 22px; } }

.loading-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .loading-container .lc {
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    background-color: #313344;
    width: 12px;
    height: 12px;
    content: '';
    border-radius: 50%; }
    .loading-container .lc-one {
      -webkit-animation-name: animeBallOne;
              animation-name: animeBallOne; }
    .loading-container .lc-two {
      -webkit-animation-name: animeBallTwo;
              animation-name: animeBallTwo;
      margin: 0 2px; }
    .loading-container .lc-three {
      -webkit-animation-name: animeBallOne;
              animation-name: animeBallOne; }

@-webkit-keyframes animeBallOne {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes animeBallOne {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes animeBallTwo {
  0% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); } }

@keyframes animeBallTwo {
  0% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.66);
            transform: scale(0.66); } }


.sign-in .si h2 {
  font-size: 20px;
  line-height: 26px;
  color: #171717;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.sign-in .si--forget-password {
  text-align: right;
  margin-top: 1rem; }

.sign-in .si--sign-up {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.sign-up {
  margin-bottom: 3rem; }
  .sign-up .su h2 {
    font-size: 20px;
    line-height: 26px;
    color: #171717; }
  .sign-up .su h2.title {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .sign-up .su h2.register {
    margin-bottom: 1rem; }
  .sign-up .su h3 {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #565656;
    margin-bottom: 1rem; }
  .sign-up .su--svg-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .sign-up .su p.description {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    margin-bottom: 1rem; }
  .sign-up .su--sign-in-btn {
    margin-top: 0.5rem;
    margin-bottom: 2rem; }
  .sign-up .su--btn-close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    border: 2px solid transparent;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .sign-up .su--btn-close:hover {
      background: #e5e5e5; }
  .sign-up .su--checkbox {
    margin-bottom: 1rem; }

.forgot-password .fp h2 {
  font-size: 20px;
  line-height: 26px;
  color: #171717;
  margin-top: 2rem;
  margin-bottom: 2rem; }

.forgot-password .fp--forget-password {
  text-align: right;
  margin-top: 1rem; }

.forgot-password .fp--sign-up {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.user-container {
  display: flex;
  justify-content: flex-end; }
  .user-container .bx--dropdown__wrapper--inline {
    width: 100%;
    display: flex;
    justify-content: flex-end; }
  .user-container .bx--list-box.bx--list-box--inline {
    background: transparent; }
    .user-container .bx--list-box.bx--list-box--inline:hover, .user-container .bx--list-box.bx--list-box--inline.bx--list-box--expanded:hover {
      background: transparent; }
    .user-container .bx--list-box.bx--list-box--inline .bx--list-box__field {
      height: 2rem; }
  .user-container .bx--list-box--inline .bx--list-box__label {
    color: #FFF; }
  .user-container .bx--list-box__menu-icon svg {
    fill: #FFF; }
  .user-container .label-login {
    display: flex;
    align-items: center; }
    .user-container .label-login svg {
      margin-right: 0.25rem; }

.header-container {
  margin-bottom: 0.5rem; }

.header-body {
  width: 100%;
  background-color: #313344;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.header-back {
  margin-left: -9px !important; }

@media (min-width: 42rem) {
  .header-title {
    font-size: 20px;
    line-height: 26px; } }

.header-subtitle {
  display: flex;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem; }
  .header-subtitle h3 {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #ffffff;
    height: 40px; }
    @media (min-width: 42rem) {
      .header-subtitle h3 {
        margin-bottom: 1rem;
        font-size: 16px;
        line-height: 24px; } }

.audience-record .ar--container {
  background-color: #f3f3f3;
  padding: 1rem; }

.audience-record .ar--title {
  display: flex;
  align-items: center;
  margin-top: 0.125rem;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px; }
  .audience-record .ar--title-primary {
    color: #0062ff; }
  .audience-record .ar--title svg {
    margin-right: 0.5rem; }

.audience-record .ar--description {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.32px;
  margin-top: 0.5rem;
  color: #565656; }

.audience-record .ar--align-items-center {
  display: flex;
  align-items: center; }

.audience-record.ar--spacing-03 {
  padding-bottom: 0.5rem; }

.audience-record.ar--spacing-05 {
  padding-bottom: 1rem; }

.audience-record.stealthy svg path {
  fill: #171717; }

.detail-audience {
  margin-bottom: 2rem; }
  .detail-audience .da--description {
    margin-bottom: 1rem; }
  .detail-audience .da--steps-box {
    background-color: #f3f3f3;
    padding: 2rem 1rem 1rem 1rem;
    margin-bottom: 1rem; }
    .detail-audience .da--steps-box .steps-row {
      margin-bottom: 1rem; }
    .detail-audience .da--steps-box .steps-description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.16px; }
  .detail-audience .da--steps p.steps--title {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    margin-bottom: 1rem; }
  .detail-audience p.title {
    margin-bottom: 1rem; }
    @media (min-width: 42rem) {
      .detail-audience p.title {
        font-size: 16px;
        line-height: 22px;
        color: #171717; } }
  .detail-audience p.description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem; }
  .detail-audience .btn--container {
    margin-top: 0.5rem;
    margin-bottom: 1rem; }
    @media (max-width: 42rem) {
      .detail-audience .btn--container {
        display: flex;
        justify-content: center; } }
  .detail-audience .label-status {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding: 4px 8px;
    border-radius: 24px;
    margin-right: 0.5rem; }
    .detail-audience .label-status--open {
      color: #A11950;
      background-color: #FFCFE1; }
    .detail-audience .label-status--in_voting {
      color: #0058A1;
      background-color: #B3E6FF; }
    .detail-audience .label-status--postulatory_stage {
      color: #6E32C9;
      background-color: #E6D6FF; }
    .detail-audience .label-status--result {
      color: #006161;
      background-color: #92EEEE; }
  .detail-audience .text-center {
    text-align: center; }
  .detail-audience .label-error {
    margin-top: 2rem; }

.request {
  cursor: pointer; }
  .request-container {
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .request h3 {
    font-size: 14px;
    line-height: 18px;
    color: #171717; }
  .request .information p.icon-description {
    display: flex;
    align-items: center; }
    .request .information p.icon-description.inline {
      float: right;
      padding-left: 0.25rem; }
    .request .information p.icon-description svg {
      margin-left: 0.25rem; }

.requests-processed-container {
  overflow-y: auto; }
  .requests-processed-container .bx--grid {
    width: calc(100vw * 3); }
    @media (min-width: 42rem) {
      .requests-processed-container .bx--grid {
        width: 100%; } }
  .requests-processed-container .bx--btn--request {
    margin-bottom: 1rem; }

.audience-requests h3,
.audience-requests h4 {
  font-size: 14px;
  line-height: 18px;
  color: #171717;
  margin-bottom: 1rem; }

@media (min-width: 42rem) {
  .audience-requests h3.question {
    font-size: 20px;
    line-height: 26px;
    color: #171717; } }

@media (min-width: 42rem) {
  .audience-requests h4.order-type {
    font-size: 28px;
    line-height: 36px;
    color: #171717; } }

.audience-requests h3,
.audience-requests h4 {
  font-size: 14px;
  line-height: 18px;
  color: #171717;
  margin-bottom: 1rem; }

@media (min-width: 42rem) {
  .audience-requests h3.question {
    font-size: 20px;
    line-height: 26px;
    color: #171717; } }

@media (min-width: 42rem) {
  .audience-requests h4.order-type {
    font-size: 28px;
    line-height: 36px;
    color: #171717; } }

.new-request {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }
  @media (min-width: 42rem) {
    .new-request {
      margin-top: 2rem;
      margin-bottom: 2rem; } }
  .new-request .nr.question {
    font-size: 20px;
    line-height: 26px;
    color: #171717;
    margin-top: 1rem;
    margin-bottom: 1.5rem; }
  .new-request .nr.conclusion {
    font-size: 14px;
    line-height: 18px;
    color: #171717;
    letter-spacing: 0.16px; }
    .new-request .nr.conclusion .bx--label {
      margin-bottom: 1rem; }
  .new-request .nr.success {
    text-align: center; }
    .new-request .nr.success h3 {
      font-size: 20px;
      line-height: 26px;
      margin-top: 1.5rem;
      margin-bottom: 3rem; }
    .new-request .nr.success p {
      font-size: 16px;
      line-height: 24px;
      color: #171717;
      margin-bottom: 2rem; }
  .new-request .nr.evidences select {
    margin-bottom: 1rem; }
  .new-request .nr.request-proofs p.title {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #171717;
    margin-top: 1rem;
    margin-bottom: 0.5rem; }
  .new-request .nr.request-proofs-testimonial p.title {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #171717;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .new-request p.default {
    color: #565656;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.32px; }
    @media (min-width: 42rem) {
      .new-request p.default {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.16px; } }

.audience-vote {
  margin-bottom: 3rem; }
  .audience-vote h3,
  .audience-vote h4 {
    font-size: 14px;
    line-height: 18px;
    color: #171717;
    margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .audience-vote h3.question {
      font-size: 20px;
      line-height: 26px;
      color: #171717; } }
  @media (min-width: 42rem) {
    .audience-vote h4.order-type {
      font-size: 28px;
      line-height: 36px;
      color: #171717; } }
  .audience-vote .av--box-vote {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; }
    .audience-vote .av--box-vote .bx--checkbox-wrapper {
      display: flex;
      align-items: center; }
  .audience-vote .av--send {
    margin-top: 1rem; }

.audience-vote {
  margin-bottom: 3rem;
  overflow-y: auto; }
  .audience-vote .bx--grid {
    width: calc(100vw * 3); }
    @media (min-width: 42rem) {
      .audience-vote .bx--grid {
        width: 100%; } }
  .audience-vote h3,
  .audience-vote h4 {
    font-size: 14px;
    line-height: 18px;
    color: #171717;
    margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .audience-vote h3.question {
      font-size: 20px;
      line-height: 26px;
      color: #171717; } }
  @media (min-width: 42rem) {
    .audience-vote h4.order-type {
      font-size: 28px;
      line-height: 36px;
      color: #171717; } }
  .audience-vote h4.order-type span.yes {
    color: #127373; }
  .audience-vote h4.order-type span.no {
    color: #AD3062; }
  .audience-vote h4.order-type span.others {
    color: #6E32C9; }
  .audience-vote .av--box-result {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 22px;
    color: #24a148; }
  .audience-vote .av--send {
    margin-top: 1rem; }
  .audience-vote .av--bar-progress {
    width: 100%;
    margin-bottom: 2.5rem; }
    .audience-vote .av--bar-progress-yes {
      background-color: #92EEEE;
      width: 60%;
      height: 16px;
      float: left; }
    .audience-vote .av--bar-progress-no {
      background-color: #FFCFE1;
      width: 30%;
      height: 16px;
      float: left; }
    .audience-vote .av--bar-progress-others {
      background-color: #6E32C9;
      width: 10%;
      height: 16px;
      float: left; }
  .audience-vote .container-label {
    margin-bottom: 1rem; }
    .audience-vote .container-label .label-status {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      padding: 4px 8px;
      border-radius: 24px; }
      .audience-vote .container-label .label-status--no {
        color: #A11950;
        background-color: #FFCFE1; }
      .audience-vote .container-label .label-status--others {
        color: #6E32C9;
        background-color: #E6D6FF; }
      .audience-vote .container-label .label-status--yes {
        color: #006161;
        background-color: #92EEEE; }

.header-inline-container .hi--body {
  background-color: #313344;
  padding: 1rem; }
  .header-inline-container .hi--body-content {
    display: flex;
    align-items: center; }
    .header-inline-container .hi--body-content-back {
      float: left;
      height: 32px;
      margin-right: 1rem; }

.header-inline-container .hi--subtitle {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #ffffff;
  margin-top: -0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 144px;
  height: 40px; }

.comment {
  margin-bottom: 0.5rem; }
  .comment .c--container {
    padding: 0.5rem;
    color: #171717;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); }
  .comment .c--name {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 42rem) {
      .comment .c--name {
        margin-top: 1rem;
        margin-bottom: 1rem; } }
  .comment .c--description {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.32px;
    color: #171717; }
    @media (min-width: 42rem) {
      .comment .c--description {
        font-size: 14px;
        line-height: 18px; } }

.detail-request .dr--info-container {
  margin-top: 1.5rem;
  margin-bottom: 1rem; }
  @media (min-width: 66rem) {
    .detail-request .dr--info-container {
      margin-top: 2rem;
      margin-bottom: 2rem; } }

.detail-request .dr--info-text {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.32px; }
  .detail-request .dr--info-text-desc {
    color: #565656;
    margin-bottom: 0.125rem; }
  .detail-request .dr--info-text-name {
    color: #171717; }

.detail-request .dr--info-like {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.32px; }

.detail-request .dr--input {
  margin-top: 1rem;
  margin-bottom: 2rem; }

.box-link .bl--container {
  background-color: #f3f3f3;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer; }
  .box-link .bl--container:hover {
    background-color: #e5e5e5; }

.box-link .bl--title {
  font-size: 16px;
  line-height: 24px;
  color: #0062ff; }

.box-link .bl--description {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: #565656; }

.box-link .blf--container {
  background-color: #f3f3f3;
  padding: 1rem;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0.5rem; }

.box-link .blf--container-label, .box-link .blf--container-remove {
  display: flex;
  align-items: center; }

.box-link .blf--container-remove {
  justify-content: center;
  line-height: 1; }
  .box-link .blf--container-remove a:hover {
    box-shadow: none; }

.box-link .blf--title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0.5rem;
  color: #171717; }

.box-link .blf--labelText {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #171717; }

.box-link .blf--labelSubText {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: #565656; }

.box-link .blf--link {
  margin-bottom: 2rem; }

.box-button .bb--container {
  background-color: #f3f3f3;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer; }
  .box-button .bb--container:hover {
    background-color: #e5e5e5; }

.box-button .bb--labelText {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #313344; }

.box-button--checked .bb--container {
  background-color: #0062ff; }
  .box-button--checked .bb--container:hover {
    background-color: #0353e9; }

.box-button--checked .bb--labelText {
  color: #FFF; }

.box-type-evidence {
  margin-bottom: 1rem;
  cursor: pointer; }
  .box-type-evidence .bte--container {
    background-color: #f3f3f3;
    padding: 0.5rem;
    color: #171717; }
    .box-type-evidence .bte--container:hover {
      background-color: #e5e5e5; }
  .box-type-evidence .bte--title {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    margin-bottom: 0.5rem; }
  .box-type-evidence .bte--description {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    color: #565656; }
    @media (min-width: 66rem) {
      .box-type-evidence .bte--description {
        line-height: 16px; } }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button,
input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file'],
input[type='text'],
input[type='password'],
textarea {
  border-radius: 0;
  font-family: inherit; }

input[type='text']::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

body {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

strong {
  font-weight: 600; }

code {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

@media screen and (-ms-high-contract: active) {
  svg {
    fill: ButtonText; } }

@-webkit-keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--assistive-text,
.bx--visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #171717;
  background-color: #ffffff;
  line-height: 1; }
  .bx--body > *,
  .bx--body > *:before,
  .bx--body > *:after {
    box-sizing: inherit; }

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #171717;
  background-color: #ffffff;
  line-height: 1; }
  body > *,
  body > *:before,
  body > *:after {
    box-sizing: inherit; }

.bx--btn-set {
  display: flex; }

.bx--btn-set > .bx--btn {
  max-width: 12.25rem;
  width: 100%; }

.bx--btn--secondary.bx--btn--disabled
+ .bx--btn--primary.bx--btn--disabled,
.bx--btn--tertiary.bx--btn--disabled
+ .bx--btn--danger.bx--btn--disabled {
  border-left: 0.0625rem solid #8c8c8c; }

.bx--btn {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 3px) 60px calc(0.875rem - 3px) 12px;
  border-radius: 0;
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);
  outline: 1px solid transparent;
  outline-offset: -4px;
  position: relative;
  max-width: 20rem; }
  .bx--btn > *,
  .bx--btn > *:before,
  .bx--btn > *:after {
    box-sizing: inherit; }
  .bx--btn:disabled, .bx--btn.bx--btn--disabled {
    cursor: not-allowed;
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe; }
  .bx--btn .bx--btn__icon {
    position: absolute;
    right: 1rem;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    -webkit-transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);
    transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--disabled > .bx--btn__icon svg,
  .bx--btn:disabled > .bx--btn__icon svg {
    fill: #8c8c8c; }

.bx--btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

.bx--btn--primary {
  background-color: #0062ff;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #ffffff; }
  .bx--btn--primary:hover {
    background-color: #0353e9; }
  .bx--btn--primary:focus {
    border-color: #0062ff;
    outline-color: #ffffff; }
  .bx--btn--primary:disabled:hover, .bx--btn--primary:disabled:focus, .bx--btn--primary:hover.bx--btn--disabled, .bx--btn--primary:focus.bx--btn--disabled {
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe;
    text-decoration: none; }
  .bx--btn--primary:active {
    background-color: #0530ad; }
  .bx--btn--primary .bx--btn__icon,
  .bx--btn--primary .bx--btn__icon path {
    fill: #ffffff; }
  .bx--btn--primary:hover {
    color: #ffffff; }

.bx--btn--secondary {
  background-color: #171717;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #ffffff; }
  .bx--btn--secondary:hover {
    background-color: #4c4c4c; }
  .bx--btn--secondary:focus {
    border-color: #0062ff;
    outline-color: #ffffff; }
  .bx--btn--secondary:disabled:hover, .bx--btn--secondary:disabled:focus, .bx--btn--secondary:hover.bx--btn--disabled, .bx--btn--secondary:focus.bx--btn--disabled {
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe;
    text-decoration: none; }
  .bx--btn--secondary:active {
    background-color: #6f6f6f; }
  .bx--btn--secondary .bx--btn__icon,
  .bx--btn--secondary .bx--btn__icon path {
    fill: #ffffff; }
  .bx--btn--secondary:hover, .bx--btn--secondary:focus {
    color: #ffffff; }

.bx--btn--tertiary {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #0062ff;
  color: #0062ff; }
  .bx--btn--tertiary:hover {
    background-color: #0353e9; }
  .bx--btn--tertiary:focus {
    border-color: #0062ff;
    outline-color: #ffffff; }
  .bx--btn--tertiary:disabled:hover, .bx--btn--tertiary:disabled:focus, .bx--btn--tertiary:hover.bx--btn--disabled, .bx--btn--tertiary:focus.bx--btn--disabled {
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe;
    text-decoration: none; }
  .bx--btn--tertiary:active {
    background-color: #0530ad; }
  .bx--btn--tertiary .bx--btn__icon,
  .bx--btn--tertiary .bx--btn__icon path {
    fill: #0062ff; }
  .bx--btn--tertiary:hover {
    color: #ffffff; }
  .bx--btn--tertiary:disabled, .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled, .bx--btn--tertiary.bx--btn--disabled, .bx--btn--tertiary.bx--btn--disabled:hover, .bx--btn--tertiary.bx--btn--disabled:focus {
    background: transparent;
    color: #bebebe; }
    .bx--btn--tertiary:disabled > .bx--btn__icon path, .bx--btn--tertiary:hover:disabled > .bx--btn__icon path, .bx--btn--tertiary:focus:disabled > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled:hover > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled:focus > .bx--btn__icon path {
      fill: #bebebe; }
  .bx--btn--tertiary:hover > .bx--btn__icon path {
    fill: #ffffff; }

.bx--btn--ghost {
  background-color: transparent;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #0062ff;
  padding: 11px 13px; }
  .bx--btn--ghost:hover {
    background-color: #e5e5e5; }
  .bx--btn--ghost:focus {
    border-color: #0062ff;
    outline-color: #ffffff; }
  .bx--btn--ghost:disabled:hover, .bx--btn--ghost:disabled:focus, .bx--btn--ghost:hover.bx--btn--disabled, .bx--btn--ghost:focus.bx--btn--disabled {
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe;
    text-decoration: none; }
  .bx--btn--ghost:active {
    background-color: #bebebe; }
  .bx--btn--ghost .bx--btn__icon,
  .bx--btn--ghost .bx--btn__icon path {
    fill: #0062ff; }
  .bx--btn--ghost .bx--btn__icon {
    position: static;
    margin-left: 0.5rem; }
  .bx--btn--ghost:hover, .bx--btn--ghost:active {
    color: #0062ff; }
    .bx--btn--ghost:hover .bx--btn__icon path, .bx--btn--ghost:active .bx--btn__icon path {
      fill: #0062ff; }
  .bx--btn--ghost:active {
    background-color: #bebebe; }
  .bx--btn--ghost:disabled, .bx--btn--ghost:hover:disabled, .bx--btn--ghost:focus:disabled, .bx--btn--ghost.bx--btn--disabled, .bx--btn--ghost.bx--btn--disabled:hover, .bx--btn--ghost.bx--btn--disabled:focus {
    color: #bebebe;
    background: transparent;
    border-color: transparent; }
    .bx--btn--ghost:disabled .bx--btn__icon path, .bx--btn--ghost:hover:disabled .bx--btn__icon path, .bx--btn--ghost:focus:disabled .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled:hover .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled:focus .bx--btn__icon path {
      fill: #bebebe; }
  .bx--btn--ghost.bx--btn--sm {
    padding: 0.375rem 1rem; }

.bx--btn--icon-only {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--btn--icon-only > *,
  .bx--btn--icon-only > *:before,
  .bx--btn--icon-only > *:after {
    box-sizing: inherit; }
  .bx--btn--icon-only:focus {
    outline: 1px solid transparent; }
    .bx--btn--icon-only:focus svg {
      outline: 1px solid #0062ff; }
  .bx--btn--icon-only::before, .bx--btn--icon-only::after {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: z("floating");
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn--icon-only::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn--icon-only::after {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.125rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    content: attr(aria-label);
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #3d3d3d; }
  .bx--btn--icon-only:hover::before, .bx--btn--icon-only:hover::after, .bx--btn--icon-only:focus::before, .bx--btn--icon-only:focus::after {
    opacity: 1; }

.bx--btn--icon-only--top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--btn--icon-only--top > *,
  .bx--btn--icon-only--top > *:before,
  .bx--btn--icon-only--top > *:after {
    box-sizing: inherit; }
  .bx--btn--icon-only--top:focus {
    outline: 1px solid transparent; }
    .bx--btn--icon-only--top:focus svg {
      outline: 1px solid #0062ff; }
  .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: z("floating");
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn--icon-only--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn--icon-only--top::after {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.125rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    content: attr(aria-label);
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #3d3d3d; }
  .bx--btn--icon-only--top:hover::before, .bx--btn--icon-only--top:hover::after, .bx--btn--icon-only--top:focus::before, .bx--btn--icon-only--top:focus::after {
    opacity: 1; }
  .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after {
    top: 0;
    left: 50%; }
  .bx--btn--icon-only--top::before {
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #3d3d3d transparent transparent transparent;
    -webkit-transform: translate(-50%, calc(-1 * (0.3125rem + 0.5rem)));
            transform: translate(-50%, calc(-1 * (0.3125rem + 0.5rem))); }
  .bx--btn--icon-only--top::after {
    left: 50%;
    -webkit-transform: translate(-50%, calc(-1 * (100% + 0.5rem + 0.3125rem)));
            transform: translate(-50%, calc(-1 * (100% + 0.5rem + 0.3125rem))); }

.bx--btn--icon-only--bottom::before, .bx--btn--icon-only--bottom::after {
  bottom: 0;
  left: 50%; }

.bx--btn--icon-only--bottom::before {
  border-width: 0 0.25rem 0.3125rem 0.25rem;
  border-color: transparent transparent #3d3d3d transparent;
  -webkit-transform: translate(-50%, calc(0.3125rem + 0.5rem));
          transform: translate(-50%, calc(0.3125rem + 0.5rem)); }

.bx--btn--icon-only--bottom::after {
  -webkit-transform: translate(-50%, calc(100% + 0.5rem + 0.3125rem));
          transform: translate(-50%, calc(100% + 0.5rem + 0.3125rem)); }

.bx--btn--icon-only,
.bx--btn--sm.bx--btn--icon-only,
.bx--btn--field.bx--btn--icon-only {
  padding-right: 0.8125rem; }
  .bx--btn--icon-only .bx--btn__icon,
  .bx--btn--sm.bx--btn--icon-only .bx--btn__icon,
  .bx--btn--field.bx--btn--icon-only .bx--btn__icon {
    position: static; }
  .bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--sm.bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--field.bx--btn--icon-only.bx--btn--ghost .bx--btn__icon {
    margin: 0; }

.bx--btn--danger {
  background-color: #dc222b;
  border-width: 3px;
  border-style: solid;
  border-color: #dc222b;
  color: #ffffff; }
  .bx--btn--danger:hover {
    background-color: #ba1b23; }
  .bx--btn--danger:focus {
    border-color: #0062ff;
    outline-color: #ffffff; }
  .bx--btn--danger:disabled:hover, .bx--btn--danger:disabled:focus, .bx--btn--danger:hover.bx--btn--disabled, .bx--btn--danger:focus.bx--btn--disabled {
    color: #8c8c8c;
    background: #bebebe;
    border-color: #bebebe;
    text-decoration: none; }
  .bx--btn--danger:active {
    background-color: #750e13; }
  .bx--btn--danger .bx--btn__icon,
  .bx--btn--danger .bx--btn__icon path {
    fill: #ffffff; }
  .bx--btn--danger:hover {
    color: #ffffff;
    border: 3px solid transparent; }

.bx--btn--sm {
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px; }

.bx--btn--field {
  height: 40px;
  min-height: 40px;
  padding: calc(0.675rem - 3px) 60px calc(0.675rem - 3px) 12px; }

.bx--btn.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 9.375rem; }
  .bx--btn.bx--skeleton:hover, .bx--btn.bx--skeleton:focus, .bx--btn.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--btn.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }

.bx--fieldset {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  margin-bottom: 2rem; }
  .bx--fieldset > *,
  .bx--fieldset > *:before,
  .bx--fieldset > *:after {
    box-sizing: inherit; }

.bx--form-item {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start; }

.bx--label {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #565656;
  font-weight: 400;
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 0.5rem;
  line-height: 1rem;
  pointer-events: none; }
  .bx--label > *,
  .bx--label > *:before,
  .bx--label > *:after {
    box-sizing: inherit; }

.bx--label .bx--tooltip__trigger {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px; }

.bx--label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--label.bx--skeleton:hover, .bx--label.bx--skeleton:focus, .bx--label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }

input[type='number'] {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

input[data-invalid],
.bx--text-input__field-wrapper[data-invalid],
.bx--text-area__wrapper[data-invalid]
> .bx--text-area--invalid,
.bx--select-input__wrapper[data-invalid],
.bx--list-box[data-invalid],
.bx--combo-box[data-invalid] .bx--text-input {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

input[data-invalid] ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement {
  max-height: 12.5rem;
  display: block;
  color: #da1e28; }

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none; }

.bx--form-requirement {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  margin: 0.25rem 0 0;
  max-height: 0;
  overflow: hidden;
  line-height: 1.5;
  display: none; }
  .bx--form-requirement > *,
  .bx--form-requirement > *:before,
  .bx--form-requirement > *:after {
    box-sizing: inherit; }

.bx--label + .bx--form__helper-text {
  margin-top: -0.375rem; }

.bx--form__helper-text {
  font-size: 0.75rem;
  font-style: italic;
  line-height: 1rem;
  letter-spacing: 0.32px;
  font-style: italic;
  color: #565656;
  z-index: 0;
  opacity: 1;
  margin-bottom: 0.5rem; }

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: #bebebe; }

@media (min-width: breakpoint("sm")) {
  .bx--form__helper-text {
    max-width: 75%; } }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

/* Stroke animations */
@-webkit-keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@-webkit-keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

.bx--loading {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 690ms;
          animation-duration: 690ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  width: 10.5rem;
  height: 10.5rem; }
  .bx--loading > *,
  .bx--loading > *:before,
  .bx--loading > *:after {
    box-sizing: inherit; }
  .bx--loading svg circle {
    -webkit-animation-name: init-stroke;
            animation-name: init-stroke;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
            animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--loading__svg {
  fill: transparent; }

.bx--loading__svg circle {
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 240; }

.bx--loading__stroke {
  stroke: #0062ff;
  stroke-dashoffset: 40; }

.bx--loading--stop {
  -webkit-animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards;
          animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards; }
  .bx--loading--stop svg circle {
    -webkit-animation-name: stroke-end;
            animation-name: stroke-end;
    -webkit-animation-duration: 700ms;
            animation-duration: 700ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
            animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
    -webkit-animation-delay: 700ms;
            animation-delay: 700ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

.bx--loading--small {
  width: 2rem;
  height: 2rem; }
  .bx--loading--small circle {
    stroke-width: 12; }

.bx--loading--small .bx--loading__svg {
  stroke: #0062ff; }

.bx--loading__background {
  stroke: #dcdcdc;
  stroke-dashoffset: 0; }

.bx--loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  z-index: z("overlay"); }

.bx--loading-overlay--stop {
  display: none; }

.bx--file {
  width: 100%; }

.bx--file--invalid {
  margin-right: 0.5rem;
  fill: #da1e28; }

.bx--file--label {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #171717;
  margin-bottom: 0.5rem; }
  .bx--file--label > *,
  .bx--file--label > *:before,
  .bx--file--label > *:after {
    box-sizing: inherit; }

.bx--file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--file-btn {
  display: inline-flex;
  margin: 0;
  padding-right: 4rem; }

.bx--label-description {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #565656;
  margin-bottom: 1rem; }
  .bx--label-description > *,
  .bx--label-description > *:before,
  .bx--label-description > *:after {
    box-sizing: inherit; }

.bx--file-container {
  display: block;
  width: 100%;
  margin-top: 1.5rem; }

.bx--file__selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  max-width: 18.75rem;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem 0 1rem;
  background-color: #f3f3f3;
  overflow: hidden; }
  .bx--file__selected-file:last-child {
    margin-bottom: 0; }
  .bx--file__selected-file .bx--inline-loading__animation,
  .bx--file__selected-file .bx--loading {
    right: -0.25rem;
    width: 1.5rem;
    height: 1.5rem; }

.bx--file__selected-file--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  margin-bottom: 0.25rem; }

.bx--file__selected-file--invalid + .bx--form-requirement {
  display: block;
  max-height: 12.5rem;
  color: #da1e28;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
  overflow: visible; }

.bx--file-filename {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  display: inline-block;
  align-items: center;
  color: #171717;
  margin-right: 1rem;
  padding: 1px 0;
  /*rtl:ignore*/
  direction: ltr;
  justify-content: flex-start;
  /*rtl:{flex-end}*/ }

.bx--file__state-container {
  display: flex;
  align-items: center; }
  .bx--file__state-container .bx--loading__svg {
    stroke: #313344; }

.bx--file__state-container .bx--file-complete {
  fill: #24a148;
  cursor: pointer; }
  .bx--file__state-container .bx--file-complete:focus {
    outline: 1px solid #0062ff; }

.bx--file__state-container .bx--file-close {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0; }

.bx--form-item.bx--checkbox-wrapper {
  margin-bottom: 0.5rem; }

.bx--form-item.bx--checkbox-wrapper:first-of-type {
  margin-top: 0.1875rem; }

.bx--label + .bx--form-item.bx--checkbox-wrapper {
  margin-top: 0; }

.bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin-bottom: 0.1875rem; }

.bx--checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--checkbox-label {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  line-height: 1.5rem;
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 1.625rem;
  min-height: 1.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bx--checkbox-label > *,
  .bx--checkbox-label > *:before,
  .bx--checkbox-label > *:after {
    box-sizing: inherit; }

.bx--checkbox-label::before {
  box-sizing: border-box;
  content: '';
  width: 1rem;
  height: 1rem;
  margin: 0.125rem;
  position: absolute;
  left: 0;
  top: 0.125rem;
  background-color: transparent;
  border: 1px solid #313344;
  border-radius: 1px; }

.bx--checkbox-label::after {
  content: '';
  position: absolute;
  left: 0.375rem;
  top: 0.5rem;
  width: 0.4375rem;
  height: 0.1875rem;
  background: none;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  -webkit-transform: scale(0) rotate(-45deg);
          transform: scale(0) rotate(-45deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  margin-top: -0.1875rem; }

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
  background-color: #171717;
  border-color: #171717;
  border-width: 1px; }

.bx--checkbox:checked + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='true']::after {
  -webkit-transform: scale(1) rotate(-45deg);
          transform: scale(1) rotate(-45deg); }

.bx--checkbox:indeterminate + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  border-left: 0 solid #ffffff;
  border-bottom: 2px solid #ffffff;
  width: 0.5rem;
  top: 0.6875rem; }

.bx--checkbox:focus + .bx--checkbox-label::before,
.bx--checkbox-label__focus::before,
.bx--checkbox:checked:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before,
.bx--checkbox:indeterminate:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before {
  border-color: #0062ff;
  border-width: 3px;
  width: 1.25rem;
  height: 1.25rem;
  left: -2px;
  top: 0; }

[disabled] ~ _ {
  font-size: inherit; }

.bx--checkbox:disabled + .bx--checkbox-label,
.bx--checkbox-label[data-contained-checkbox-disabled='true'] {
  cursor: not-allowed;
  color: #bebebe; }

.bx--checkbox:disabled + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-disabled='true']::before {
  border-color: #bebebe; }

.bx--checkbox:checked:disabled + .bx--checkbox-label::before,
.bx--checkbox:indeterminate:disabled
+ .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'][data-contained-checkbox-disabled='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'][data-contained-checkbox-disabled='true']::before {
  background-color: #bebebe; }

.bx--radio-button-group {
  display: flex;
  align-items: center;
  margin-top: 0.375rem; }

.bx--radio-button-group--vertical {
  flex-direction: column;
  align-items: flex-start; }
  .bx--radio-button-group--vertical.bx--radio-button-group--label-left {
    align-items: flex-end; }
  .bx--radio-button-group--vertical .bx--radio-button__label {
    margin-right: 0; }
  .bx--radio-button-group--vertical .bx--radio-button__label:not(:last-of-type) {
    margin-bottom: 0.5rem; }

.bx--radio-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  visibility: inherit; }

.bx--radio-button__label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem; }

.bx--radio-button__appearance {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #313344;
  flex-shrink: 0;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem; }
  .bx--radio-button__appearance > *,
  .bx--radio-button__appearance > *:before,
  .bx--radio-button__appearance > *:after {
    box-sizing: inherit; }

.bx--radio-button:checked
+ .bx--radio-button__label
.bx--radio-button__appearance {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #313344; }
  .bx--radio-button:checked
+ .bx--radio-button__label
.bx--radio-button__appearance:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #313344; }

[disabled] ~ _ {
  font-size: inherit; }

.bx--radio-button:disabled + .bx--radio-button__label {
  color: #bebebe;
  cursor: not-allowed; }

.bx--radio-button:disabled
+ .bx--radio-button__label
.bx--radio-button__appearance,
.bx--radio-button:disabled:checked
+ .bx--radio-button__label
.bx--radio-button__appearance {
  border-color: #bebebe; }
  .bx--radio-button:disabled
+ .bx--radio-button__label
.bx--radio-button__appearance::before,
  .bx--radio-button:disabled:checked
+ .bx--radio-button__label
.bx--radio-button__appearance::before {
    background-color: #bebebe; }

.bx--radio-button:focus
+ .bx--radio-button__label
.bx--radio-button__appearance {
  box-shadow: 0 0 0 2px #0062ff;
  outline: 1px solid transparent; }

.bx--radio-button__label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 6.25rem;
  height: 1.125rem; }
  .bx--radio-button__label.bx--skeleton:hover, .bx--radio-button__label.bx--skeleton:focus, .bx--radio-button__label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--radio-button__label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }

.bx--radio-button__label.bx--skeleton
.bx--radio-button__appearance {
  display: none; }

.bx--radio-button-wrapper .bx--radio-button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; }

.bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 1rem; }

.bx--radio-button-group--vertical
.bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 0;
  margin-bottom: 0.5rem; }

.bx--radio-button-group--label-right .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-right
.bx--radio-button__label {
  flex-direction: row; }

.bx--radio-button-group--label-left .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left
.bx--radio-button__label {
  flex-direction: row-reverse; }

.bx--radio-button-group--label-left
.bx--radio-button__appearance,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left
.bx--radio-button__appearance {
  margin-right: 0;
  margin-left: 0.5rem; }

.bx--select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  position: relative;
  display: flex;
  flex-direction: column; }
  .bx--select > *,
  .bx--select > *:before,
  .bx--select > *:after {
    box-sizing: inherit; }

.bx--select-input__wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  .bx--select-input__wrapper:hover .bx--select-input {
    background-color: #e5e5e5; }

.bx--select-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  height: 2.5rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: block;
  width: 14rem;
  min-width: 8rem;
  max-width: 28rem;
  padding: 0 2.625rem 0 1rem;
  color: #171717;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--select-input:hover {
    background-color: #e5e5e5; }
  .bx--select-input::-ms-expand {
    display: none; }

@-moz-document url-prefix() {
  .bx--select-input {
    padding-top: 0.25rem; } }
  .bx--select-input:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px; }
  .bx--select-input:disabled, .bx--select-input:hover:disabled {
    cursor: not-allowed;
    background-color: #f3f3f3;
    color: #bebebe;
    border-bottom-color: #f3f3f3; }

.bx--select--disabled .bx--label,
.bx--select--disabled .bx--form__helper-text {
  color: #bebebe; }

.bx--select-input__wrapper[data-invalid] .bx--select-input {
  padding-right: 4rem; }

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select-input__wrapper[data-invalid]
.bx--select-input:focus {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

.bx--form-requirement {
  display: block;
  color: #da1e28;
  font-weight: 400;
  overflow: visible; }

.bx--select-input:disabled ~ .bx--select__arrow {
  fill: #bebebe; }

.bx--select--light .bx--select-input {
  background-color: #ffffff; }
  .bx--select--light .bx--select-input:hover {
    background-color: #f3f3f3; }
  .bx--select--light .bx--select-input:disabled, .bx--select--light .bx--select-input:hover:disabled {
    cursor: not-allowed;
    background-color: #f3f3f3;
    color: #bebebe; }

.bx--select__arrow {
  fill: #313344;
  position: absolute;
  right: 1rem;
  pointer-events: none; }

.bx--select-input__wrapper[data-invalid]
.bx--select-input
~ .bx--select__invalid-icon {
  position: absolute;
  right: 2.125rem; }

.bx--select-input__wrapper[data-invalid]
.bx--select-input
~ .bx--select__invalid-icon {
  fill: #da1e28; }

.bx--select-optgroup,
.bx--select-option {
  color: #171717; }

.bx--select-option[disabled] {
  opacity: 0.5;
  cursor: not-allowed; }

@-moz-document url-prefix() {
  .bx--select-option {
    background-color: #f3f3f3;
    color: #171717; }
  .bx--select-optgroup {
    color: #171717; } }

.bx--select--inline {
  display: flex;
  flex-direction: row;
  align-items: center; }

.bx--select--inline.bx--select--invalid .bx--label,
.bx--select--inline.bx--select--invalid
.bx--form__helper-text {
  margin-top: 0.8125rem;
  align-self: flex-start; }

.bx--select--inline .bx--form__helper-text {
  margin-bottom: 0;
  margin-left: 0.5rem; }

.bx--select--inline .bx--label {
  white-space: nowrap;
  margin: 0 0.5rem 0 0; }

.bx--select--inline .bx--select-input {
  background-color: transparent;
  color: #171717;
  border-bottom: none;
  padding-left: 0.5rem;
  padding-right: 1.625rem; }

@-moz-document url-prefix() {
  .bx--select--inline .bx--select-input {
    padding-top: 0; } }
  .bx--select--inline .bx--select-input:hover {
    background-color: #e5e5e5; }

.bx--select--inline .bx--select__arrow {
  bottom: auto;
  top: 1.125rem;
  right: 0.5rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input {
  padding-right: 3.125rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input
~ .bx--select__invalid-icon {
  right: 1.5rem; }

.bx--select--inline .bx--select-input:disabled {
  color: #bebebe;
  cursor: not-allowed; }
  .bx--select--inline .bx--select-input:disabled:hover {
    background-color: transparent; }
  .bx--select--inline .bx--select-input:disabled ~ * {
    cursor: not-allowed; }

.bx--select--inline .bx--select-input:disabled {
  cursor: not-allowed; }

.bx--select.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  width: 100%;
  height: 2.5rem; }
  .bx--select.bx--skeleton:hover, .bx--select.bx--skeleton:focus, .bx--select.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--select.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }

.bx--select.bx--skeleton .bx--select-input {
  display: none; }

.bx--text-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f3f3f3;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  color: #171717;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input > *,
  .bx--text-input > *:before,
  .bx--text-input > *:after {
    box-sizing: inherit; }
  .bx--text-input:focus, .bx--text-input:active {
    outline: 2px solid #0062ff;
    outline-offset: -2px; }
  .bx--text-input-wrapper svg[hidden] {
    display: none; }

.bx--password-input {
  padding-right: 2.5rem; }

.bx--text-input::-webkit-input-placeholder {
  color: #8c8c8c; }

.bx--text-input--light {
  background-color: #ffffff; }

.bx--text-input__field-wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  .bx--text-input__field-wrapper .bx--text-input__invalid-icon {
    position: absolute;
    right: 1rem;
    fill: #da1e28; }
  .bx--text-input__field-wrapper .bx--text-input--password__visibility {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: visible;
    position: absolute;
    height: 1rem;
    width: 1rem;
    right: 1rem;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility > *,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility > *:before,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility > *:after {
      box-sizing: inherit; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus {
      outline: 1px solid transparent; }
      .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus svg {
        outline: 1px solid #0062ff; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      position: absolute;
      z-index: z("floating");
      display: flex;
      align-items: center;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
      transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before {
      width: 0;
      height: 0;
      border-style: solid;
      content: ''; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      max-width: 13rem;
      height: auto;
      padding: 0.125rem 1rem;
      border-radius: 0.125rem;
      color: #ffffff;
      font-weight: 400;
      content: attr(aria-label);
      text-align: left;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      pointer-events: none;
      background-color: #3d3d3d; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover::after, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus::after {
      opacity: 1; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      bottom: 0;
      left: 50%; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before {
      border-width: 0 0.25rem 0.3125rem 0.25rem;
      border-color: transparent transparent #3d3d3d transparent;
      -webkit-transform: translate(-50%, calc(0.3125rem + 0.5rem));
              transform: translate(-50%, calc(0.3125rem + 0.5rem)); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      -webkit-transform: translate(-50%, calc(100% + 0.5rem + 0.3125rem));
              transform: translate(-50%, calc(100% + 0.5rem + 0.3125rem)); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility svg {
      fill: #0062ff; }
      .bx--text-input__field-wrapper .bx--text-input--password__visibility svg:hover {
        fill: #0353e9; }
  .bx--text-input__field-wrapper .bx--text-input--invalid {
    padding-right: 2.5rem; }
  .bx--text-input__field-wrapper .bx--text-input--invalid.bx--password-input {
    padding-right: 4rem; }
  .bx--text-input__field-wrapper .bx--text-input--invalid
+ .bx--text-input--password__visibility {
    right: 2.5rem; }

.bx--text-input:disabled
+ .bx--text-input--password__visibility
svg {
  opacity: 0.5;
  cursor: not-allowed; }

.bx--text-input:disabled {
  cursor: not-allowed;
  outline: none;
  background-color: #f3f3f3;
  border-bottom: 1px solid transparent;
  color: #bebebe; }

.bx--text-input--light:disabled {
  background-color: #ffffff; }

.bx--text-input:disabled::-webkit-input-placeholder {
  opacity: 1;
  color: #bebebe; }

.bx--text-input:disabled::-moz-placeholder {
  opacity: 1;
  color: #bebebe; }

.bx--text-input:disabled:-ms-input-placeholder {
  opacity: 1;
  color: #bebebe; }

.bx--text-input:disabled::-ms-input-placeholder {
  opacity: 1;
  color: #bebebe; }

.bx--text-input:disabled::placeholder {
  opacity: 1;
  color: #bebebe; }

.bx--text-input--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  box-shadow: none; }
  .bx--text-input--invalid .bx--text-input--password__visibility {
    right: 2.5rem; }

.bx--text-area {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f3f3f3;
  width: 100%;
  min-width: 10rem;
  min-height: 2.5rem;
  padding: 0.6875rem 1rem;
  color: #171717;
  order: 3;
  resize: vertical;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-area > *,
  .bx--text-area > *:before,
  .bx--text-area > *:after {
    box-sizing: inherit; }
  .bx--text-area ~ .bx--form__helper-text {
    margin-top: 0;
    order: 2;
    font-style: italic; }
  .bx--text-area ~ .bx--form-requirement {
    order: 4;
    color: #da1e28;
    font-weight: 400;
    margin-top: 0.25rem; }
    .bx--text-area ~ .bx--form-requirement::before {
      display: none; }

.bx--text-area:focus,
.bx--text-area:active {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

.bx--text-area::-webkit-input-placeholder {
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::-moz-placeholder {
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area:-ms-input-placeholder {
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::-ms-input-placeholder {
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::placeholder {
  color: #8c8c8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area--light {
  background-color: #ffffff; }

.bx--text-area--invalid {
  padding-right: 2.5rem; }

.bx--text-area__wrapper {
  position: relative;
  display: flex; }

.bx--text-area__invalid-icon {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  fill: #da1e28; }

.bx--text-area:disabled {
  cursor: not-allowed;
  outline: none;
  background-color: #f3f3f3;
  border-bottom: 1px solid transparent; }

.bx--text-area:disabled::-webkit-input-placeholder {
  color: #bebebe; }

.bx--text-area:disabled::-moz-placeholder {
  color: #bebebe; }

.bx--text-area:disabled:-ms-input-placeholder {
  color: #bebebe; }

.bx--text-area:disabled::-ms-input-placeholder {
  color: #bebebe; }

.bx--text-area:disabled::placeholder {
  color: #bebebe; }

bx--text-area.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1);
  height: 6.25rem; }
  bx--text-area.bx--skeleton:hover, bx--text-area.bx--skeleton:focus, bx--text-area.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  bx--text-area.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
  bx--text-area.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::-moz-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton:-ms-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::-ms-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::placeholder {
    color: transparent; }

.bx--link {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  color: #0062ff;
  text-decoration: none;
  outline: none;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--link > *,
  .bx--link > *:before,
  .bx--link > *:after {
    box-sizing: inherit; }
  .bx--link:hover {
    color: #0062ff;
    box-shadow: 0 1px currentColor; }
  .bx--link:active, .bx--link:active:visited {
    color: #171717;
    box-shadow: 0 1px currentColor; }
  .bx--link:focus {
    box-shadow: 0 3px currentColor; }
  .bx--link:not([href]) {
    color: #bebebe;
    cursor: not-allowed;
    pointer-events: none;
    touch-action: none; }
    .bx--link:not([href]):hover {
      box-shadow: none; }
  .bx--link:visited {
    color: #0062ff; }

.bx--link--visited {
  color: #8a3ffc; }

.bx--link--disabled {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  display: inline;
  color: #bebebe;
  font-weight: 400;
  cursor: not-allowed; }
  .bx--link--disabled > *,
  .bx--link--disabled > *:before,
  .bx--link--disabled > *:after {
    box-sizing: inherit; }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--list-box__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-template: auto auto / auto auto;
  grid-gap: 0.25rem; }
  .bx--list-box__wrapper--inline .bx--label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px; }
  .bx--list-box__wrapper--inline .bx--label,
  .bx--list-box__wrapper--inline .bx--form__helper-text,
  .bx--list-box__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--list-box__wrapper--inline .bx--form__helper-text {
    max-width: none; }
  .bx--list-box__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--list-box {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  position: relative;
  width: 100%;
  height: 2.5rem;
  max-height: 2.5rem;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  cursor: pointer;
  color: #171717;
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box > *,
  .bx--list-box > *:before,
  .bx--list-box > *:after {
    box-sizing: inherit; }
  .bx--list-box:hover {
    background-color: #e5e5e5; }

.bx--list-box--expanded {
  border-bottom-color: #dcdcdc; }

.bx--list-box--expanded:hover {
  background-color: #f3f3f3; }

.bx--list-box--expanded:hover.bx--list-box--light:hover {
  background-color: #ffffff; }

.bx--list-box .bx--text-input {
  height: 100%; }

.bx--list-box__invalid-icon {
  position: absolute;
  top: 0.75rem;
  right: 2.5rem;
  fill: #da1e28; }

.bx--list-box--inline .bx--list-box__invalid-icon {
  top: 0.5rem; }

.bx--list-box[data-invalid] .bx--list-box__field {
  border-bottom: 0;
  padding-right: 4rem; }

.bx--list-box[data-invalid].bx--list-box--inline
.bx--list-box__field {
  padding-right: 3.5rem; }

.bx--list-box--light {
  background-color: #ffffff; }

.bx--list-box--light.bx--list-box--expanded {
  border-bottom-width: 0; }

.bx--list-box--disabled:hover {
  background-color: #f3f3f3; }

.bx--list-box--light.bx--list-box--disabled {
  background-color: #ffffff; }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__field:focus {
  border-bottom-width: 0;
  outline: none; }

.bx--list-box--disabled .bx--list-box__label,
.bx--list-box--disabled.bx--list-box--inline
.bx--list-box__label {
  color: #bebebe; }

.bx--list-box--disabled .bx--list-box__menu-icon > svg {
  fill: #bebebe; }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__menu-icon {
  cursor: not-allowed; }

.bx--list-box--disabled .bx--list-box__menu-item,
.bx--list-box--disabled .bx--list-box__menu-item:hover,
.bx--list-box--disabled
.bx--list-box__menu-item--highlighted {
  color: #bebebe;
  text-decoration: none; }

.bx--list-box--disabled .bx--list-box__selection:hover {
  cursor: not-allowed; }

.bx--list-box--disabled.bx--list-box[data-invalid]
.bx--list-box__field {
  padding-right: 3rem; }

.bx--list-box--disabled.bx--list-box[data-invalid].bx--list-box--inline
.bx--list-box__field {
  padding-right: 2rem; }

.bx--list-box.bx--list-box--inline {
  background-color: #ffffff;
  border-width: 0; }
  .bx--list-box.bx--list-box--inline:hover {
    background-color: #e5e5e5; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded {
  border-bottom-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded
.bx--list-box__field[aria-expanded='true'] {
  border-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--disabled:hover,
.bx--list-box.bx--list-box--inline.bx--list-box--expanded:hover {
  background-color: #ffffff; }

.bx--list-box.bx--list-box--inline,
.bx--list-box.bx--list-box--inline
.bx--list-box__field {
  height: 2rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__field {
  padding: 0 2rem 0 0.5rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__menu-icon {
  right: 0.5rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__invalid-icon {
  right: 2rem; }

.bx--list-box--inline .bx--list-box__label {
  color: #171717; }

.bx--list-box__field {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  height: 2.5rem;
  padding: 0 3rem 0 1rem;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .bx--list-box__field > *,
  .bx--list-box__field > *:before,
  .bx--list-box__field > *:after {
    box-sizing: inherit; }
  .bx--list-box__field::-moz-focus-inner {
    border: 0; }

.bx--list-box__field:focus {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

.bx--list-box__field[disabled] {
  outline: none;
  color: #bebebe; }

.bx--list-box__field .bx--text-input[value] {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input[value] {
  padding-right: 6.125rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input[value]
+ .bx--list-box__invalid-icon {
  right: 4.125rem; }

.bx--list-box__field .bx--text-input[value=''] {
  padding-right: 3rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input[value=''] {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input[value='']
+ .bx--list-box__invalid-icon {
  right: 2.5rem; }

.bx--list-box__label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #171717;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.bx--list-box__menu-icon {
  position: absolute;
  right: 1rem;
  height: 100%;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer; }

.bx--list-box__menu-icon > svg {
  fill: #171717;
  height: 100%; }

.bx--list-box__menu-icon--open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--list-box__selection {
  position: absolute;
  right: 2.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__selection:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px; }

.bx--list-box[data-invalid] .bx--list-box__selection {
  right: 3.5625rem; }

.bx--list-box__selection > svg {
  fill: #565656; }

.bx--list-box--disabled .bx--list-box__selection:focus {
  outline: none; }

.bx--list-box--disabled .bx--list-box__selection > svg {
  fill: #bebebe; }

.bx--list-box__selection--multi {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background-color: #3d3d3d;
  height: 1.5rem;
  width: auto;
  color: #ffffff;
  line-height: 0;
  padding: 0.5rem;
  padding-right: 0.125rem;
  margin-right: 0.625rem;
  border-radius: 0.75rem; }

.bx--list-box__selection--multi > svg {
  fill: #ffffff;
  margin-left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem; }

.bx--list-box__selection--multi > svg:hover {
  border-radius: 50%;
  background-color: #4c4c4c;
  fill: #ffffff; }

.bx--list-box__selection--multi:focus,
.bx--list-box__selection--multi:hover {
  outline: none; }

.bx--list-box__menu {
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f3f3f3;
  max-height: 8.75rem;
  overflow-y: auto;
  z-index: 9100; }

.bx--list-box__menu-item {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  height: 2.5rem;
  color: #565656;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative; }
  .bx--list-box__menu-item:hover {
    background-color: #e5e5e5; }
  .bx--list-box__menu-item:active {
    background-color: #dcdcdc; }

.bx--list-box--disabled .bx--list-box__menu-item:hover {
  background-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item__option:hover {
  border-top-color: #dcdcdc; }

.bx--list-box__menu-item:first-of-type
.bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item:hover
.bx--list-box__menu-item__option {
  color: #171717; }

.bx--list-box__menu-item:hover
+ .bx--list-box__menu-item
.bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item:hover
+ .bx--list-box__menu-item
.bx--list-box__menu-item__option {
  border-top-color: #dcdcdc; }

.bx--list-box__menu-item__option {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  height: 2.5rem;
  color: #565656;
  text-decoration: none;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.6875rem 0;
  margin: 0 1rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top-color: #dcdcdc;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .bx--list-box__menu-item__option:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px;
    margin: 0;
    padding: 0.6875rem 1rem;
    border-color: transparent; }
  .bx--list-box__menu-item__option:hover {
    color: #171717;
    border-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item:hover
.bx--list-box__menu-item__option,
.bx--list-box--disabled .bx--list-box__menu-item__option {
  color: #bebebe; }

.bx--list-box.bx--list-box--inline
.bx--list-box__menu-item__option {
  margin: 0 0.5rem; }
  .bx--list-box.bx--list-box--inline
.bx--list-box__menu-item__option:focus {
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.bx--list-box__menu-item--highlighted {
  background-color: #e5e5e5;
  color: #171717;
  border-color: transparent; }

.bx--list-box__menu-item--highlighted
.bx--list-box__menu-item__option {
  color: #171717; }

.bx--list-box__menu-item--active {
  color: #171717;
  border-bottom-color: #dcdcdc; }
  .bx--list-box__menu-item--active:hover {
    background-color: #dcdcdc; }

.bx--list-box__menu-item--active
.bx--list-box__menu-item__option {
  color: #171717; }

.bx--list-box__menu-item .bx--checkbox-label {
  width: 100%; }

.bx--list-box__menu-item .bx--checkbox-label-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.bx--list-box input[role='combobox'] {
  background-color: inherit;
  min-width: 0; }

.bx--list--nested,
.bx--list--unordered,
.bx--list--ordered {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  counter-reset: listitem; }
  .bx--list--nested > *,
  .bx--list--nested > *:before,
  .bx--list--nested > *:after,
  .bx--list--unordered > *,
  .bx--list--unordered > *:before,
  .bx--list--unordered > *:after,
  .bx--list--ordered > *,
  .bx--list--ordered > *:before,
  .bx--list--ordered > *:after {
    box-sizing: inherit; }

.bx--list__item {
  font-weight: 400;
  color: #171717;
  list-style-type: none;
  counter-increment: listitem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  position: relative; }

.bx--list__item:before {
  position: absolute;
  left: -0.5rem; }

.bx--list--nested {
  margin-bottom: 0.25rem;
  margin-left: 2rem; }

.bx--list--unordered > .bx--list__item:before,
.bx--list--ordered > .bx--list__item:before {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem; }

.bx--list--unordered
.bx--list--nested
> .bx--list__item:before,
.bx--list--ordered
.bx--list--nested
> .bx--list__item:before {
  margin-right: 0.5rem;
  display: inline-block; }

.bx--list--unordered > .bx--list__item:before {
  content: '\002013'; }

.bx--list--unordered
ul.bx--list--nested
> .bx--list__item:before {
  content: '\0025AA'; }

.bx--list--unordered
ol.bx--list--nested
> .bx--list__item:before {
  content: counter(listitem, lower-alpha) "."; }

.bx--list--ordered > .bx--list__item:before {
  content: counter(listitem) "."; }

.bx--list--ordered ol.bx--list--nested {
  counter-reset: ol-counter; }

.bx--list--ordered
ol.bx--list--nested
> .bx--list__item {
  counter-increment: ol-counter; }

.bx--list--ordered
ol.bx--list--nested
> .bx--list__item:before {
  content: counter(ol-counter, lower-alpha) ".";
  width: 0.6rem; }

.bx--list--ordered
ul.bx--list--nested
> .bx--list__item:before {
  content: '\0025AA'; }

.bx--dropdown__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-template: auto auto / auto -webkit-min-content;
  grid-template: auto auto / auto min-content;
  grid-gap: 0.25rem; }
  .bx--dropdown__wrapper--inline .bx--label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px; }
  .bx--dropdown__wrapper--inline .bx--label,
  .bx--dropdown__wrapper--inline .bx--form__helper-text,
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  list-style: none;
  display: block;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid #8c8c8c;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  color: #171717;
  outline: 2px solid transparent;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--dropdown > *,
  .bx--dropdown > *:before,
  .bx--dropdown > *:after {
    box-sizing: inherit; }
  .bx--dropdown:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px; }
  .bx--dropdown:hover {
    background-color: #e5e5e5; }

.bx--dropdown--open {
  border-bottom-color: #dcdcdc; }

.bx--dropdown--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }
  .bx--dropdown--invalid .bx--dropdown-text {
    padding-right: 3.5rem; }
  .bx--dropdown--invalid + .bx--form-requirement {
    display: inline-block;
    max-height: 12.5rem;
    color: #da1e28; }

.bx--dropdown__invalid-icon {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  fill: #da1e28;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.bx--dropdown--open:hover {
  background-color: #f3f3f3; }

.bx--dropdown--open:focus {
  outline: 1px solid transparent; }

.bx--dropdown--open .bx--dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--dropdown--light {
  background-color: #ffffff; }

.bx--dropdown--up .bx--dropdown-list {
  bottom: 2rem; }

.bx--dropdown__arrow {
  fill: #313344;
  position: absolute;
  right: 1rem;
  top: 0.8125rem;
  pointer-events: none;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-transform-origin: 50% 45%;
          transform-origin: 50% 45%; }

.bx--dropdown-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: block;
  height: 2.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 2.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.bx--dropdown-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  position: absolute;
  z-index: 9100;
  max-height: 0;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  overflow: hidden auto; }
  .bx--dropdown-list > *,
  .bx--dropdown-list > *:before,
  .bx--dropdown-list > *:after {
    box-sizing: inherit; }

.bx--dropdown-item {
  -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  opacity: 0; }
  .bx--dropdown-item:hover {
    background-color: #e5e5e5; }
    .bx--dropdown-item:hover + .bx--dropdown-item .bx--dropdown-link {
      border-color: transparent; }
  .bx--dropdown-item:active {
    background-color: #dcdcdc; }
  .bx--dropdown-item:first-of-type .bx--dropdown-link {
    border-top-color: transparent; }

.bx--dropdown-item:last-of-type .bx--dropdown-link {
  border-bottom: none; }

.bx--dropdown-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  height: 2.5rem;
  color: #565656;
  text-decoration: none;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.6875rem 0;
  margin: 0 1rem;
  border: 1px solid transparent;
  border-top-color: #dcdcdc;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .bx--dropdown-link:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px;
    margin: 0;
    padding: 0.6875rem 1rem; }
  .bx--dropdown-link:hover {
    color: #171717;
    border-color: transparent; }

.bx--dropdown-item:hover .bx--dropdown-link {
  border-bottom-color: #e5e5e5; }

.bx--dropdown--selected {
  display: none; }

.bx--dropdown--open .bx--dropdown__arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.bx--dropdown--open .bx--dropdown-list {
  max-height: 15rem;
  -webkit-transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--dropdown--open .bx--dropdown-item {
  opacity: 1; }

.bx--dropdown--disabled {
  border-bottom-color: transparent;
  cursor: not-allowed; }
  .bx--dropdown--disabled:hover {
    background-color: #f3f3f3; }
  .bx--dropdown--disabled:focus {
    outline: none; }
  .bx--dropdown--disabled .bx--dropdown-text {
    color: #bebebe; }
  .bx--dropdown--disabled .bx--dropdown__arrow {
    fill: #bebebe; }
  .bx--dropdown--disabled.bx--dropdown--light:hover {
    background-color: #ffffff; }

.bx--dropdown--auto-width {
  width: auto;
  max-width: 25rem; }

.bx--dropdown--inline {
  justify-self: start;
  display: inline-block;
  border-bottom-color: transparent;
  width: auto;
  height: 2rem;
  background-color: #ffffff;
  -webkit-transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--dropdown--inline:hover {
    background-color: #e5e5e5; }
  .bx--dropdown--inline.bx--dropdown--disabled {
    background-color: #ffffff; }
  .bx--dropdown--inline .bx--dropdown__arrow {
    top: 0.5rem;
    right: 0.5rem; }

.bx--dropdown--inline.bx--dropdown--open {
  background-color: transparent; }

.bx--dropdown--inline .bx--dropdown-text {
  display: inline-block;
  padding: 0.4375rem 2rem 0.4375rem 0.75rem;
  height: 2rem;
  overflow: visible;
  color: #171717; }

.bx--dropdown--inline.bx--dropdown--disabled
.bx--dropdown-text {
  color: #bebebe; }

.bx--dropdown--inline.bx--dropdown--disabled:focus
.bx--dropdown-text {
  outline: 0; }

.bx--dropdown--inline.bx--dropdown--invalid
.bx--dropdown__invalid-icon {
  right: 2rem; }

.bx--dropdown--inline.bx--dropdown--invalid
.bx--dropdown-text {
  padding-right: 3.5rem; }

.bx--dropdown--inline.bx--dropdown--open:focus
.bx--dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--dropdown--inline .bx--dropdown-link {
  font-weight: normal;
  margin-right: 0.75rem;
  margin-left: 0.625rem; }
  .bx--dropdown--inline .bx--dropdown-link:focus {
    margin: 0;
    padding-left: 0.625rem; }

.bx--dropdown-v2.bx--skeleton,
.bx--dropdown.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: rgba(61, 112, 178, 0.1); }
  .bx--dropdown-v2.bx--skeleton:hover, .bx--dropdown-v2.bx--skeleton:focus, .bx--dropdown-v2.bx--skeleton:active,
  .bx--dropdown.bx--skeleton:hover,
  .bx--dropdown.bx--skeleton:focus,
  .bx--dropdown.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--dropdown-v2.bx--skeleton:before,
  .bx--dropdown.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    background: rgba(61, 112, 178, 0.1);
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

.bx--modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: background-color 720ms cubic-bezier(0.4, 0.14, 1, 1), opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), z-index 720ms cubic-bezier(0.4, 0.14, 1, 1), visibility 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 1, 1), opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), z-index 720ms cubic-bezier(0.4, 0.14, 1, 1), visibility 240ms cubic-bezier(0.4, 0.14, 1, 1); }
  .bx--modal.is-visible {
    z-index: 9000;
    visibility: visible;
    opacity: 1;
    background-color: rgba(23, 23, 23, 0.5);
    -webkit-transition: background-color 720ms cubic-bezier(0, 0, 0.3, 1), opacity 240ms cubic-bezier(0, 0, 0.3, 1), z-index 720ms cubic-bezier(0, 0, 0.3, 1), visibility 240ms cubic-bezier(0, 0, 0.3, 1);
    transition: background-color 720ms cubic-bezier(0, 0, 0.3, 1), opacity 240ms cubic-bezier(0, 0, 0.3, 1), z-index 720ms cubic-bezier(0, 0, 0.3, 1), visibility 240ms cubic-bezier(0, 0, 0.3, 1); }
  .bx--modal .bx--text-input,
  .bx--modal .bx--select-input {
    background-color: #ffffff; }

.bx--modal--danger .bx--modal-container {
  border-top-color: #da1e28; }

.bx--modal.is-visible .bx--modal-container {
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1), -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.bx--modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: translate3d(0, -24px, 0);
          transform: translate3d(0, -24px, 0);
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1), -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1); }
  @media (min-width: 42rem) {
    .bx--modal-container {
      width: 50%;
      max-width: 768px;
      max-height: 90%;
      height: auto; } }
  @media (min-width: 66rem) {
    .bx--modal-container {
      max-height: 80%; } }

.bx--modal-header,
.bx--modal-content {
  width: 75%;
  padding-left: 1rem; }

.bx--modal-header,
.bx--modal-footer {
  flex-shrink: 0; }

.bx--modal-header {
  padding-top: 1rem;
  margin-bottom: 1rem; }

.bx--modal-header__label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #565656;
  margin-bottom: 0.25rem; }

.bx--modal-header__heading {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0;
  color: #171717; }

.bx--modal-content {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  overflow-y: auto;
  margin-bottom: 3rem;
  color: #171717;
  font-weight: 400; }

.bx--modal-content > * {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px; }

.bx--modal-footer {
  display: flex;
  margin-top: auto;
  height: 4rem;
  background-color: #dcdcdc; }
  .bx--modal-footer button.bx--btn {
    max-width: none;
    flex: 1 1;
    height: 4rem;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 2rem; }

.bx--modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  padding: 0.75rem;
  border: 2px solid transparent;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--modal-close:hover {
    background-color: #e5e5e5; }
  .bx--modal-close:focus {
    outline: none;
    border-color: #0062ff; }

.bx--modal-close__icon {
  fill: #171717;
  height: 1.25rem;
  width: 1.25rem; }

.bx--inline-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: flex;
  justify-content: space-between;
  height: auto;
  min-height: 3rem;
  min-width: 18rem;
  max-width: 18rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .bx--inline-notification > *,
  .bx--inline-notification > *:before,
  .bx--inline-notification > *:after {
    box-sizing: inherit; }
  @media (min-width: 42rem) {
    .bx--inline-notification {
      max-width: 38rem; } }
  @media (min-width: 66rem) {
    .bx--inline-notification {
      max-width: 46rem; } }
  @media (min-width: 99rem) {
    .bx--inline-notification {
      max-width: 52rem; } }

.bx--inline-notification--low-contrast {
  color: #171717;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2); }

.bx--inline-notification--error {
  border-left: 3px solid #fb4b53;
  background: #3d3d3d; }
  .bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--error .bx--toast-notification__icon {
    fill: #fb4b53; }

.bx--inline-notification--low-contrast.bx--inline-notification--error {
  border-left: 3px solid #da1e28;
  background: #fff0f1; }
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--toast-notification__icon {
    fill: #da1e28; }

.bx--inline-notification--success {
  border-left: 3px solid #3dbb61;
  background: #3d3d3d; }
  .bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--success .bx--toast-notification__icon {
    fill: #3dbb61; }

.bx--inline-notification--low-contrast.bx--inline-notification--success {
  border-left: 3px solid #24a148;
  background: #dafbe4; }
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--toast-notification__icon {
    fill: #24a148; }

.bx--inline-notification--info {
  border-left: 3px solid #408bfc;
  background: #3d3d3d; }
  .bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--info .bx--toast-notification__icon {
    fill: #408bfc; }

.bx--inline-notification--low-contrast.bx--inline-notification--info {
  border-left: 3px solid #054ada;
  background: #edf4ff; }
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--toast-notification__icon {
    fill: #054ada; }

.bx--inline-notification--info .bx--inline-notification__icon {
  display: none; }

.bx--inline-notification--warning {
  border-left: 3px solid #fdd13a;
  background: #3d3d3d; }
  .bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--warning .bx--toast-notification__icon {
    fill: #fdd13a; }

.bx--inline-notification--low-contrast.bx--inline-notification--warning {
  border-left: 3px solid #fdd13a;
  background: #fff8e1; }
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--toast-notification__icon {
    fill: #fdd13a; }

.bx--inline-notification--warning
.bx--inline-notification__icon
path[opacity='0'] {
  fill: #171717;
  opacity: 1; }

.bx--inline-notification__details {
  display: flex;
  margin: 0 1rem; }

.bx--inline-notification__icon {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.875rem; }

.bx--inline-notification__text-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem 0; }

.bx--inline-notification__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin: 0 0.25rem 0 0;
  white-space: nowrap; }

.bx--inline-notification__subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  word-break: break-word; }

.bx--inline-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--inline-notification__close-button:focus {
    outline: 2px solid #0062ff;
    outline-offset: -2px; }
  .bx--inline-notification__close-button .bx--inline-notification__close-icon {
    height: 1rem;
    width: 1rem;
    fill: #ffffff; }

.bx--inline-notification--low-contrast
.bx--inline-notification__close-button
.bx--inline-notification__close-icon {
  fill: #313344; }

.bx--accordion {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  list-style: none;
  width: 100%; }
  .bx--accordion > *,
  .bx--accordion > *:before,
  .bx--accordion > *:after {
    box-sizing: inherit; }

.bx--accordion__item {
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border-top: 1px solid #dcdcdc;
  overflow: visible; }
  .bx--accordion__item:last-child {
    border-bottom: 1px solid #dcdcdc; }

.bx--accordion__heading {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0.375rem 0;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  margin: 0;
  -webkit-transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__heading > *,
  .bx--accordion__heading > *:before,
  .bx--accordion__heading > *:after {
    box-sizing: inherit; }
  .bx--accordion__heading::-moz-focus-inner {
    border: 0; }
  .bx--accordion__heading:hover:before, .bx--accordion__heading:focus:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px); }
  .bx--accordion__heading:hover:before {
    background-color: #e5e5e5; }
  .bx--accordion__heading:focus {
    outline: none; }
  .bx--accordion__heading:focus:before {
    border: 2px solid #0062ff;
    box-sizing: border-box; }

.bx--accordion__arrow {
  outline: 2px solid transparent;
  outline-offset: -2px;
  flex: 0 0 1rem;
  width: 1rem;
  height: 1rem;
  margin: 0 1rem 0 0;
  fill: #313344;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--accordion__title {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  margin: 0 0 0 1rem;
  width: 100%;
  text-align: left;
  z-index: 0; }

.bx--accordion__content {
  -webkit-transition: height cubic-bezier(0.2, 0, 0.38, 0.9) 110ms, padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: height cubic-bezier(0.2, 0, 0.38, 0.9) 110ms, padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  padding-left: 1rem;
  padding-right: 25%;
  height: 0;
  visibility: hidden;
  opacity: 0; }
  @media (max-width: 42rem) {
    .bx--accordion__content {
      padding-right: 3rem; } }
  .bx--accordion__content p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.16px; }

.bx--accordion__item--active {
  overflow: visible; }
  .bx--accordion__item--active .bx--accordion__content {
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    height: auto;
    visibility: visible;
    opacity: 1;
    -webkit-transition: height cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
    transition: height cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__item--active .bx--accordion__arrow {
    /*rtl:ignore*/
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    fill: #313344; }

.bx--accordion.bx--skeleton .bx--accordion__heading,
.bx--accordion.bx--skeleton .bx--accordion__button {
  cursor: default; }

.bx--accordion.bx--skeleton .bx--accordion__arrow {
  pointer-events: none;
  fill: #313344;
  cursor: default; }
  .bx--accordion.bx--skeleton .bx--accordion__arrow:hover, .bx--accordion.bx--skeleton .bx--accordion__arrow:focus, .bx--accordion.bx--skeleton .bx--accordion__arrow:active {
    border: none;
    outline: none;
    cursor: default; }

.bx--skeleton
.bx--accordion__heading:focus
.bx--accordion__arrow {
  border: none;
  outline: none;
  cursor: default; }

.bx--accordion__title.bx--skeleton__text {
  margin-bottom: 0; }

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

.bx--inline-loading {
  display: flex;
  width: 100%;
  align-items: center; }
  .bx--inline-loading .bx--loading__svg circle {
    stroke-width: 12; }
  .bx--inline-loading .bx--loading__stroke {
    stroke-dashoffset: 99; }

.bx--inline-loading__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #565656; }

.bx--inline-loading__animation {
  position: relative;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.bx--inline-loading__checkmark-container {
  width: 0.75rem;
  position: absolute;
  top: 0.75rem; }
  .bx--inline-loading__checkmark-container[hidden] {
    display: none; }

.bx--inline-loading__checkmark {
  fill: none;
  stroke: #0062ff;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-width: 1.8;
  stroke-dasharray: 12;
  stroke-dashoffset: 12;
  -webkit-animation-name: stroke;
          animation-name: stroke;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.bx--loading--small .bx--inline-loading__svg {
  stroke: #0062ff; }

/* If IE11 Don't show check animation */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .bx--inline-loading__checkmark-container {
    top: 1px;
    right: 0.5rem; }
  .bx--inline-loading__checkmark {
    -webkit-animation: none;
            animation: none;
    stroke-dashoffset: 0;
    stroke-dasharray: 0; } }

.bx--header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background-color: #171717;
  border-bottom: 1px solid #3d3d3d;
  z-index: 6000; }

.bx--header__action {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms;
  transition: background-color 110ms, border-color 110ms; }
  .bx--header__action > *,
  .bx--header__action > *:before,
  .bx--header__action > *:after {
    box-sizing: inherit; }
  .bx--header__action::-moz-focus-inner {
    border: 0; }

.bx--header__action
> svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active
> svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active
> svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action
> svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active
> svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active
> svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action:hover {
  background-color: #353535; }

.bx--header__action--active {
  border-left: 1px solid #3d3d3d;
  border-right: 1px solid #3d3d3d;
  border-bottom: 1px solid #171717; }

.bx--header__action:focus {
  border-color: #ffffff;
  outline: none; }

.bx--header__action:active {
  background-color: #3d3d3d; }

.bx--header__action > svg {
  fill: #ffffff; }

.bx--header__menu-trigger {
  margin-right: -0.5rem; }

.bx--header__menu-trigger > svg {
  fill: #f3f3f3; }

.bx--header__menu-trigger:hover {
  fill: #2c2c2c; }

.bx--header__menu-toggle {
  display: none; }
  @media (max-width: 66rem) {
    .bx--header__menu-toggle {
      display: block; } }

a.bx--header__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2rem 0 1rem;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0.125rem solid transparent;
  -webkit-transition: border-color 110ms;
  transition: border-color 110ms;
  outline: none; }

a.bx--header__name:focus {
  border-color: #ffffff; }

.bx--header__name--prefix {
  font-weight: 400; }

a.bx--header__name,
a.bx--header__name:hover {
  color: #f3f3f3; }

.bx--header__nav {
  height: 100%;
  padding-left: 1rem;
  position: relative; }
  .bx--header__nav::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 1.5rem;
    width: 0.0625rem;
    background-color: #3d3d3d; }

.bx--header__menu-bar[role='menubar'] {
  display: flex;
  height: 100%; }

a.bx--header__menu-item[role='menuitem'] {
  display: flex;
  align-items: center;
  color: #bebebe;
  padding: 0 1rem;
  height: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms, color 110ms;
  transition: background-color 110ms, border-color 110ms, color 110ms; }

a.bx--header__menu-item[role='menuitem']:hover {
  background-color: #2c2c2c;
  color: #f3f3f3; }

.bx--header__action:active,
a.bx--header__menu-item[role='menuitem']:active {
  background-color: #3d3d3d;
  color: #f3f3f3; }

a.bx--header__menu-item[role='menuitem']:focus {
  border-color: #ffffff;
  color: #f3f3f3;
  outline: none; }

a.bx--header__menu-item[role='menuitem']:hover > svg,
a.bx--header__menu-item[role='menuitem']:active > svg,
a.bx--header__menu-item[role='menuitem']:focus > svg {
  fill: #f3f3f3; }

.bx--header__submenu {
  position: relative; }

.bx--header__menu-title[role='menuitem'][aria-haspopup='true'] {
  position: relative; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] {
  background-color: #282828;
  color: #ffffff;
  z-index: 6001; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
> .bx--header__menu-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--header__menu[role='menu'] {
  display: none; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 12.5rem;
  flex-direction: column;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  background-color: #282828;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 6000; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:hover {
  background-color: #353535; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:active {
  background-color: #3d3d3d; }

.bx--header__menu .bx--header__menu-item[role='menuitem'] {
  height: 3rem; }

.bx--header__menu
.bx--header__menu-item[role='menuitem']:hover {
  background-color: #282828;
  color: #f3f3f3; }

.bx--header__menu-arrow {
  fill: #bebebe;
  margin-left: 0.5rem;
  -webkit-transition: fill 110ms, -webkit-transform 110ms;
  transition: fill 110ms, -webkit-transform 110ms;
  transition: transform 110ms, fill 110ms;
  transition: transform 110ms, fill 110ms, -webkit-transform 110ms; }

.bx--header__global {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  height: 100%; }

.bx--skip-to-content {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--skip-to-content:focus {
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: auto;
  height: 3rem;
  clip: auto;
  border: 4px solid #0062ff;
  z-index: 9999;
  background-color: #171717;
  color: #f3f3f3;
  outline: none;
  padding: 0 1rem; }

.bx--header-panel {
  -webkit-transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
          transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  position: fixed;
  top: 3rem;
  bottom: 0;
  right: 0;
  width: 0;
  background-color: #171717;
  border: none;
  will-change: width;
  -webkit-transition: width 0.11s;
  transition: width 0.11s;
  overflow: hidden;
  z-index: 6000;
  color: #bebebe; }

.bx--header-panel--expanded {
  width: 16rem;
  border-left: 1px solid #3d3d3d;
  border-right: 1px solid #3d3d3d; }

.bx--panel--overlay {
  position: fixed;
  top: 3rem;
  right: 0;
  bottom: 0;
  width: 16rem;
  will-change: transform;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  padding: 1rem 0;
  overflow-y: auto;
  z-index: 1000;
  background-color: #171717;
  height: 100%;
  overflow-x: hidden;
  -webkit-transition: -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--panel--expanded {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.bx--product-switcher__search {
  padding: 0 1rem;
  margin-bottom: 1.5rem; }

.bx--search--shell input {
  background-color: #dcdcdc; }

.bx--product-switcher__subheader,
.bx--product-switcher__all-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  padding: 0.5rem;
  color: #bebebe; }

.bx--product-switcher__subheader {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn,
.bx--product-switcher__back-btn {
  display: inline-block;
  background: transparent;
  width: 100%;
  border: none;
  color: #0062ff;
  cursor: pointer;
  text-align: left; }

.bx--product-switcher__all-btn:hover,
.bx--product-switcher__back-btn:hover {
  text-decoration: underline; }

.bx--product-switcher__all-btn:focus,
.bx--product-switcher__back-btn:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px #0062ff; }

.bx--product-switcher__back-btn {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  padding: 0.5rem 1rem; }

.bx--product-switcher__back-arrow {
  fill: #0062ff;
  margin-right: 1rem; }

.bx--product-list__item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.bx--product-list__item:hover {
  background: #dcdcdc; }

.bx--product-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  text-decoration: none; }

.bx--product-link:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px #0062ff; }

.bx--product-switcher__icon {
  margin-right: 1rem; }

.bx--product-link__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin-left: 0.25rem;
  font-weight: 400;
  color: #bebebe; }

.bx--product-switcher__product-list .bx--overflow-menu {
  display: none;
  justify-content: center;
  align-items: center;
  width: 2.5rem; }
  .bx--product-switcher__product-list .bx--overflow-menu.bx--overflow-menu--open {
    display: flex; }

.bx--product-switcher__product-list .bx--overflow-menu > svg {
  fill: #bebebe; }

.bx--product-switcher__product-list .bx--overflow-menu:hover {
  background: #bebebe; }

.bx--product-switcher__product-list
.bx--overflow-menu:hover
> svg {
  fill: #bebebe; }

.bx--product-switcher__product-list .bx--overflow-menu:focus {
  display: flex;
  outline: none;
  box-shadow: inset 0 0 0 3px #0062ff; }

.bx--product-switcher__product-list
.bx--overflow-menu-options__option:hover {
  background: #ffffff; }

.bx--product-list__item:hover .bx--overflow-menu {
  display: flex; }

.bx--switcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #bebebe; }

.bx--switcher__item {
  width: 100%; }

.bx--switcher__item:nth-child(1),
.bx--switcher__item:nth-last-child(3) {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }
  .bx--switcher__item:nth-child(1)::after,
  .bx--switcher__item:nth-last-child(3)::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.5625rem;
    width: 13.875rem;
    margin-left: 1rem;
    border-top: 1px solid #3d3d3d; }

.bx--switcher__item:nth-last-child(3) {
  margin-top: 0; }

.bx--switcher__item:nth-child(2),
.bx--switcher__item:nth-last-child(2) {
  padding-top: 0.5625rem; }

.bx--switcher__item-link {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: block;
  text-decoration: none;
  padding: 0.375rem 1rem;
  color: #bebebe; }
  .bx--switcher__item-link:hover {
    background: #2c2c2c;
    color: #f3f3f3; }
  .bx--switcher__item-link:focus {
    outline: 2px solid #ffffff;
    outline-offset: -2px; }
  .bx--switcher__item-link:active {
    background: #3d3d3d;
    color: #f3f3f3; }

.bx--switcher__item-link--selected {
  background: #282828;
  color: #f3f3f3; }

.bx--side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3rem;
  max-width: 16rem;
  color: #565656;
  background-color: #ffffff;
  will-change: width;
  -webkit-transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  overflow: hidden;
  z-index: 6000; }

.bx--side-nav--ux {
  top: 3rem;
  width: 16rem; }
  @media (max-width: 66rem) {
    .bx--side-nav--ux {
      width: 0; } }

.bx--side-nav:not(.bx--side-nav--fixed):hover,
.bx--side-nav--expanded {
  width: 16rem; }

.bx--header ~ .bx--side-nav {
  top: 3rem;
  height: calc(100% - 48px); }

.bx--side-nav--fixed {
  width: 16rem; }

.bx--side-nav--collapsed {
  width: 16rem;
  -webkit-transform: translateX(-16rem);
          transform: translateX(-16rem); }

.bx--side-nav--collapsed {
  width: 16rem;
  -webkit-transform: translateX(-16rem);
          transform: translateX(-16rem); }

.bx--side-nav__navigation {
  display: flex;
  flex-direction: column;
  height: 100%; }

.bx--side-nav__header {
  display: flex;
  border-bottom: 1px solid #3d3d3d;
  width: 100%;
  height: 3rem;
  max-width: 100%; }
  .bx--side-nav:hover .bx--side-nav__header,
  .bx--side-nav--fixed .bx--side-nav__header,
  .bx--side-nav--expanded .bx--side-nav__header {
    height: auto; }

.bx--side-nav--ux .bx--side-nav__header {
  height: auto; }

.bx--side-nav__details {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  flex: 1 1;
  min-width: 0;
  opacity: 0;
  visibility: hidden; }
  .bx--side-nav:hover .bx--side-nav__details,
  .bx--side-nav--fixed .bx--side-nav__details,
  .bx--side-nav--expanded .bx--side-nav__details {
    visibility: visible;
    opacity: 1; }

.bx--side-nav--ux .bx--side-nav__details {
  opacity: 1;
  visibility: inherit; }

.bx--side-nav__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-top: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bx--side-nav__title,
.bx--side-nav__select {
  padding-left: 0.5rem; }

.bx--side-nav__switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.bx--side-nav__switcher-chevron {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  fill: #565656; }

.bx--side-nav__select {
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  flex: 1 1;
  background-color: #171717;
  color: #f3f3f3;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.75rem;
  height: 2rem;
  min-width: 0;
  padding-right: 2rem;
  -webkit-transition: outline 110ms;
  transition: outline 110ms; }

.bx--side-nav__select:focus {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

.bx--side-nav__footer {
  flex: 0 0 3rem;
  width: 100%;
  background-color: #ffffff; }

.bx--side-nav__toggle {
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: left;
  -webkit-transition: outline 110ms;
  transition: outline 110ms; }
  .bx--side-nav__toggle > *,
  .bx--side-nav__toggle > *:before,
  .bx--side-nav__toggle > *:after {
    box-sizing: inherit; }
  .bx--side-nav__toggle::-moz-focus-inner {
    border: 0; }

.bx--side-nav__toggle:focus {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

.bx--side-nav__toggle {
  padding-left: 1rem; }

.bx--side-nav__items {
  flex: 1 1;
  overflow: hidden;
  padding: 1rem 0 0; }
  .bx--side-nav:hover .bx--side-nav__items,
  .bx--side-nav--fixed .bx--side-nav__items,
  .bx--side-nav--expanded .bx--side-nav__items {
    overflow-y: auto; }

.bx--side-nav--ux .bx--side-nav__items {
  overflow-y: auto; }

.bx--side-nav__item {
  width: 3rem;
  height: 3rem;
  overflow: hidden; }
  .bx--side-nav:hover .bx--side-nav__item,
  .bx--side-nav--fixed .bx--side-nav__item,
  .bx--side-nav--expanded .bx--side-nav__item {
    width: auto;
    height: auto; }

.bx--side-nav--ux .bx--side-nav__item {
  width: auto;
  height: auto; }

.bx--side-nav__item:not(.bx--side-nav__item--active):hover
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__submenu:hover,
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover,
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[role='menuitem']:not(.bx--side-nav__link--current):not([aria-current='page']):hover {
  background-color: #e5e5e5;
  color: #171717; }

.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover
> span,
.bx--side-nav__item:not(.bx--side-nav__item--active)
.bx--side-nav__menu-item
> .bx--side-nav__link:hover
> span {
  color: #171717; }

.bx--side-nav__submenu[aria-haspopup='true'] {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: #565656;
  height: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms; }
  .bx--side-nav__submenu[aria-haspopup='true'] > *,
  .bx--side-nav__submenu[aria-haspopup='true'] > *:before,
  .bx--side-nav__submenu[aria-haspopup='true'] > *:after {
    box-sizing: inherit; }
  .bx--side-nav__submenu[aria-haspopup='true']::-moz-focus-inner {
    border: 0; }

.bx--side-nav__submenu:focus {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

.bx--side-nav__submenu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.bx--side-nav__icon.bx--side-nav__submenu-chevron {
  flex: 1 1;
  display: flex;
  justify-content: flex-end; }

.bx--side-nav__submenu-chevron > svg {
  -webkit-transition: -webkit-transform 110ms;
  transition: -webkit-transform 110ms;
  transition: transform 110ms;
  transition: transform 110ms, -webkit-transform 110ms;
  height: 1rem;
  width: 1rem; }

.bx--side-nav__submenu[aria-expanded='true']
.bx--side-nav__submenu-chevron
> svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--side-nav__item--active .bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #171717; }

.bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false'] {
  background-color: #e5e5e5;
  color: #171717;
  position: relative; }
  .bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false']::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #0062ff; }

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  font-weight: 600;
  color: #171717; }

.bx--side-nav__menu[role='menu'] {
  display: block;
  visibility: hidden;
  max-height: 0;
  -webkit-transition: 240ms max-height cubic-bezier(0.2, 0, 1, 0.9), 240ms visibility cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 240ms max-height cubic-bezier(0.2, 0, 1, 0.9), 240ms visibility cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--side-nav__submenu[aria-expanded='true']
+ .bx--side-nav__menu[role='menu'] {
  max-height: 93.75rem;
  visibility: inherit;
  -webkit-transition: 240ms max-height cubic-bezier(0, 0, 0.38, 0.9), 240ms visibility cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 240ms max-height cubic-bezier(0, 0, 0.38, 0.9), 240ms visibility cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[role='menuitem'] {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400; }

.bx--side-nav__item.bx--side-nav__item--icon
a.bx--side-nav__link[role='menuitem'] {
  padding-left: 4.5rem; }

.bx--side-nav__menu[role='menu']
a.bx--side-nav__link--current,
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #dcdcdc; }
  .bx--side-nav__menu[role='menu']
a.bx--side-nav__link--current > span,
  .bx--side-nav__menu[role='menu']
a.bx--side-nav__link[aria-current='page'] > span,
  a.bx--side-nav__link--current > span {
    color: #171717;
    font-weight: 600; }

a.bx--side-nav__link--current > span.bx--side-nav__link-text {
  color: #171717;
  font-weight: 600; }

a.bx--side-nav__link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  min-height: 2rem;
  padding: 0 1rem;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms; }

a.bx--side-nav__link > .bx--side-nav__link-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #565656;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

a.bx--side-nav__link:focus {
  outline: 2px solid #0062ff;
  outline-offset: -2px; }

a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  font-weight: 600; }

a.bx--side-nav__link[aria-current='page']::before,
a.bx--side-nav__link--current::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0062ff; }

.bx--side-nav__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem; }

.bx--side-nav__icon:not(.bx--side-nav__submenu-chevron) {
  margin-right: 1.5rem; }

.bx--side-nav__icon > svg {
  fill: #565656; }

.bx--side-nav__icon > svg.bx--side-nav-collapse-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-expand-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-collapse-icon {
  display: block; }

.bx--side-nav--fixed a.bx--side-nav__link,
.bx--side-nav--fixed
.bx--side-nav__submenu[aria-haspopup='true'] {
  padding-left: 1rem; }

.bx--side-nav--fixed
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link {
  padding-left: 2rem; }

.bx--navigation {
  position: fixed;
  top: 3rem;
  bottom: 0;
  left: 0;
  background-color: #282828;
  width: 16rem;
  z-index: 9100;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  color: #f3f3f3; }

.bx--navigation--right {
  left: auto;
  right: 0; }

.bx--navigation svg {
  fill: #f3f3f3; }

.bx--navigation-section:not(:last-child)::after {
  display: block;
  content: '';
  height: 1px;
  background-color: #3d3d3d;
  margin: 0 1rem; }

.bx--navigation-item {
  position: relative;
  display: flex;
  align-items: center; }

.bx--navigation-item--active > a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation-item--active::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0062ff; }

a.bx--navigation-link {
  display: flex;
  align-items: center;
  color: #f3f3f3;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  min-height: 2.5rem;
  padding-left: 1rem; }

a.bx--navigation-link:hover {
  background-color: #333333;
  color: #ffffff; }

a.bx--navigation-link:focus {
  outline: 0.1875rem solid #0062ff;
  outline-offset: -0.1875rem; }

.bx--navigation-item--icon > a.bx--navigation-link {
  padding-left: 0; }

.bx--navigation__category {
  width: 100%; }

.bx--navigation__category-toggle {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center; }
  .bx--navigation__category-toggle > *,
  .bx--navigation__category-toggle > *:before,
  .bx--navigation__category-toggle > *:after {
    box-sizing: inherit; }
  .bx--navigation__category-toggle::-moz-focus-inner {
    border: 0; }

.bx--navigation__category-toggle:hover {
  background-color: #333333; }

.bx--navigation__category-toggle:focus {
  outline: 0.1875rem solid #0062ff;
  outline-offset: -0.1875rem; }

.bx--navigation__category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  width: 100%;
  color: #f3f3f3;
  font-size: 0.875rem;
  font-weight: 400;
  min-height: 2.5rem;
  padding-left: 1rem; }

.bx--navigation-item--icon .bx--navigation__category-title {
  padding-left: 0; }

.bx--navigation__category-items {
  display: none;
  visibility: hidden; }

.bx--navigation__category-item > a.bx--navigation-link {
  display: flex;
  align-items: center;
  min-height: 2rem;
  padding-left: 2rem; }

.bx--navigation__category-item {
  position: relative; }

.bx--navigation-item--icon
.bx--navigation__category-item
> a.bx--navigation-link {
  padding-left: 3.5rem; }

.bx--navigation__category-item--active::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0062ff; }

.bx--navigation__category-item--active
> a.bx--navigation-link {
  font-weight: 600;
  color: #ffffff; }

.bx--navigation__category--expanded
.bx--navigation__category-title {
  font-weight: 600; }

.bx--navigation__category--expanded
.bx--navigation__category-title
> svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--navigation__category--expanded
.bx--navigation__category-items {
  display: block;
  visibility: inherit; }

.bx--navigation-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  min-width: 3rem;
  height: 2.5rem;
  margin-right: 0.5rem; }

.bx--content {
  background: #ffffff;
  padding: 2rem;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: margin-left; }

.bx--header ~ .bx--content {
  margin-top: 3rem; }

.bx--side-nav ~ .bx--content {
  margin-left: 3rem; }

.bx--side-nav.bx--side-nav--expanded ~ .bx--content {
  margin-left: 16rem; }

.bx--accordion__title {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  color: #171717; }
  @media (min-width: 66rem) {
    .bx--accordion__title {
      font-size: 20px;
      line-height: 26px; } }

.bx--accordion__heading {
  padding: 1rem 0; }
  .bx--accordion__heading:hover:before {
    background: transparent; }
  .bx--accordion__heading:focus:before {
    border: none; }

.bx--accordion__content {
  padding: 0; }
  .bx--accordion__content p {
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.16px;
    color: #171717; }

@media (min-width: 66rem) {
  .bx--accordion__item {
    border: none; } }

.bx--accordion__item:last-child {
  border-bottom: none; }

.bx--accordion__item--active .bx--accordion__content {
  padding-bottom: 1rem; }

.bx--link,
.bx--btn {
  font-family: "Fira Sans", sans-serif; }

.bx--btn--gray {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #8c8c8c;
  color: #8c8c8c; }
  .bx--btn--gray svg path {
    fill: #8c8c8c !important; }
  .bx--btn--gray:hover {
    color: #ffffff;
    background-color: #8c8c8c; }
    .bx--btn--gray:hover svg path {
      fill: #ffffff !important; }
  .bx--btn--gray:active {
    color: #ffffff;
    background-color: #313344; }
  .bx--btn--gray:focus::after {
    border-color: #313344; }

.bx--btn--disabled svg path {
  fill: #8c8c8c !important; }

.bx--btn--container-center {
  display: flex;
  justify-content: center; }

@media (max-width: 42rem) {
  .bx--btn--full-with {
    max-width: 100%;
    width: 100%; } }

.bx--file-uploader-button {
  min-height: 3rem; }

.bx--label,
.bx--form__helper-text,
.bx--text-input,
.bx--form-item,
.bx--select-input,
.bx--checkbox-label,
.bx--inline-loading__text,
.bx--form-requirement {
  font-family: "Fira Sans", sans-serif; }

.bx--text-input__field-wrapper {
  width: 100%; }

.bx--fieldset {
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .bx--fieldset {
      margin-bottom: 1.5rem; } }

.bx--inline-loading {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  @media (min-width: 42rem) {
    .bx--inline-loading {
      justify-content: center; } }

@media (max-width: 42rem) {
  .bx--inline-notification {
    max-width: 42rem; } }

.bx--form-item.bx--checkbox-wrapper {
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .bx--form-item.bx--checkbox-wrapper {
      margin-bottom: 1.5rem; } }

.bx--modal-content {
  width: 100%;
  padding-right: 1rem; }

.bx--grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 42rem) {
    .bx--grid {
      padding-left: 2rem;
      padding-right: 2rem; } }

@media (min-width: 99rem) {
  .bx--grid--full-width {
    max-width: 100%; } }

.bx--row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.bx--grid--condensed .bx--row:not(:last-of-type) {
  margin-bottom: 0.125rem; }

.bx--row--condensed + .bx--row--condensed {
  margin-top: 0.125rem; }

.bx--col {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col,
  .bx--grid--condensed .bx--col {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-1,
  .bx--grid--condensed .bx--col-sm-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-2,
  .bx--grid--condensed .bx--col-sm-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-3,
  .bx--grid--condensed .bx--col-sm-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-4,
  .bx--grid--condensed .bx--col-sm-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm,
.bx--col-sm--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm,
  .bx--grid--condensed .bx--col-sm, .bx--row--condensed
  .bx--col-sm--auto,
  .bx--grid--condensed
  .bx--col-sm--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col,
.bx--col-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.bx--col--auto,
.bx--col-sm--auto {
  flex: 1 0;
  width: auto;
  max-width: 100%; }

.bx--col-sm-1 {
  flex: 0 0 25%;
  max-width: 25%; }

.bx--col-sm-2 {
  flex: 0 0 50%;
  max-width: 50%; }

.bx--col-sm-3 {
  flex: 0 0 75%;
  max-width: 75%; }

.bx--col-sm-4 {
  flex: 0 0 100%;
  max-width: 100%; }

.bx--offset-sm-0 {
  margin-left: 0; }

.bx--offset-sm-1 {
  margin-left: 25%; }

.bx--offset-sm-2 {
  margin-left: 50%; }

.bx--offset-sm-3 {
  margin-left: 75%; }

.bx--col-md-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-1,
  .bx--grid--condensed .bx--col-md-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-2,
  .bx--grid--condensed .bx--col-md-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-3,
  .bx--grid--condensed .bx--col-md-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-4,
  .bx--grid--condensed .bx--col-md-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-5,
  .bx--grid--condensed .bx--col-md-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-6,
  .bx--grid--condensed .bx--col-md-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-7,
  .bx--grid--condensed .bx--col-md-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-8,
  .bx--grid--condensed .bx--col-md-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md,
.bx--col-md--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md,
  .bx--grid--condensed .bx--col-md, .bx--row--condensed
  .bx--col-md--auto,
  .bx--grid--condensed
  .bx--col-md--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 42rem) {
  .bx--col,
  .bx--col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-md--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-md-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .bx--col-md-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-md-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .bx--col-md-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-md-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .bx--col-md-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-md-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .bx--col-md-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-md-0 {
    margin-left: 0; }
  .bx--offset-md-1 {
    margin-left: 12.5%; }
  .bx--offset-md-2 {
    margin-left: 25%; }
  .bx--offset-md-3 {
    margin-left: 37.5%; }
  .bx--offset-md-4 {
    margin-left: 50%; }
  .bx--offset-md-5 {
    margin-left: 62.5%; }
  .bx--offset-md-6 {
    margin-left: 75%; }
  .bx--offset-md-7 {
    margin-left: 87.5%; } }

.bx--col-lg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-1,
  .bx--grid--condensed .bx--col-lg-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-2,
  .bx--grid--condensed .bx--col-lg-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-3,
  .bx--grid--condensed .bx--col-lg-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-4,
  .bx--grid--condensed .bx--col-lg-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-5,
  .bx--grid--condensed .bx--col-lg-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-6,
  .bx--grid--condensed .bx--col-lg-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-7,
  .bx--grid--condensed .bx--col-lg-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-8,
  .bx--grid--condensed .bx--col-lg-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-9,
  .bx--grid--condensed .bx--col-lg-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-10,
  .bx--grid--condensed .bx--col-lg-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-11,
  .bx--grid--condensed .bx--col-lg-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-12,
  .bx--grid--condensed .bx--col-lg-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-13,
  .bx--grid--condensed .bx--col-lg-13 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-14,
  .bx--grid--condensed .bx--col-lg-14 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-15,
  .bx--grid--condensed .bx--col-lg-15 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-16,
  .bx--grid--condensed .bx--col-lg-16 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg,
.bx--col-lg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg,
  .bx--grid--condensed .bx--col-lg, .bx--row--condensed
  .bx--col-lg--auto,
  .bx--grid--condensed
  .bx--col-lg--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 66rem) {
  .bx--col,
  .bx--col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-lg--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-lg-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .bx--col-lg-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .bx--col-lg-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .bx--col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-lg-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .bx--col-lg-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .bx--col-lg-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .bx--col-lg-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-lg-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .bx--col-lg-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .bx--col-lg-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .bx--col-lg-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-lg-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .bx--col-lg-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .bx--col-lg-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .bx--col-lg-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-lg-0 {
    margin-left: 0; }
  .bx--offset-lg-1 {
    margin-left: 6.25%; }
  .bx--offset-lg-2 {
    margin-left: 12.5%; }
  .bx--offset-lg-3 {
    margin-left: 18.75%; }
  .bx--offset-lg-4 {
    margin-left: 25%; }
  .bx--offset-lg-5 {
    margin-left: 31.25%; }
  .bx--offset-lg-6 {
    margin-left: 37.5%; }
  .bx--offset-lg-7 {
    margin-left: 43.75%; }
  .bx--offset-lg-8 {
    margin-left: 50%; }
  .bx--offset-lg-9 {
    margin-left: 56.25%; }
  .bx--offset-lg-10 {
    margin-left: 62.5%; }
  .bx--offset-lg-11 {
    margin-left: 68.75%; }
  .bx--offset-lg-12 {
    margin-left: 75%; }
  .bx--offset-lg-13 {
    margin-left: 81.25%; }
  .bx--offset-lg-14 {
    margin-left: 87.5%; }
  .bx--offset-lg-15 {
    margin-left: 93.75%; } }

.bx--col-xlg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-1,
  .bx--grid--condensed .bx--col-xlg-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-2,
  .bx--grid--condensed .bx--col-xlg-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-3,
  .bx--grid--condensed .bx--col-xlg-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-4,
  .bx--grid--condensed .bx--col-xlg-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-5,
  .bx--grid--condensed .bx--col-xlg-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-6,
  .bx--grid--condensed .bx--col-xlg-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-7,
  .bx--grid--condensed .bx--col-xlg-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-8,
  .bx--grid--condensed .bx--col-xlg-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-9,
  .bx--grid--condensed .bx--col-xlg-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-10,
  .bx--grid--condensed .bx--col-xlg-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-11,
  .bx--grid--condensed .bx--col-xlg-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-12,
  .bx--grid--condensed .bx--col-xlg-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-13,
  .bx--grid--condensed .bx--col-xlg-13 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-14,
  .bx--grid--condensed .bx--col-xlg-14 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-15,
  .bx--grid--condensed .bx--col-xlg-15 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-16,
  .bx--grid--condensed .bx--col-xlg-16 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg,
.bx--col-xlg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg,
  .bx--grid--condensed .bx--col-xlg, .bx--row--condensed
  .bx--col-xlg--auto,
  .bx--grid--condensed
  .bx--col-xlg--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 82rem) {
  .bx--col,
  .bx--col-xlg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-xlg--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-xlg-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .bx--col-xlg-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .bx--col-xlg-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .bx--col-xlg-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-xlg-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .bx--col-xlg-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .bx--col-xlg-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .bx--col-xlg-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-xlg-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .bx--col-xlg-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .bx--col-xlg-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .bx--col-xlg-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-xlg-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .bx--col-xlg-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .bx--col-xlg-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .bx--col-xlg-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-xlg-0 {
    margin-left: 0; }
  .bx--offset-xlg-1 {
    margin-left: 6.25%; }
  .bx--offset-xlg-2 {
    margin-left: 12.5%; }
  .bx--offset-xlg-3 {
    margin-left: 18.75%; }
  .bx--offset-xlg-4 {
    margin-left: 25%; }
  .bx--offset-xlg-5 {
    margin-left: 31.25%; }
  .bx--offset-xlg-6 {
    margin-left: 37.5%; }
  .bx--offset-xlg-7 {
    margin-left: 43.75%; }
  .bx--offset-xlg-8 {
    margin-left: 50%; }
  .bx--offset-xlg-9 {
    margin-left: 56.25%; }
  .bx--offset-xlg-10 {
    margin-left: 62.5%; }
  .bx--offset-xlg-11 {
    margin-left: 68.75%; }
  .bx--offset-xlg-12 {
    margin-left: 75%; }
  .bx--offset-xlg-13 {
    margin-left: 81.25%; }
  .bx--offset-xlg-14 {
    margin-left: 87.5%; }
  .bx--offset-xlg-15 {
    margin-left: 93.75%; } }

.bx--col-max-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-1,
  .bx--grid--condensed .bx--col-max-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-2,
  .bx--grid--condensed .bx--col-max-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-3,
  .bx--grid--condensed .bx--col-max-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-4,
  .bx--grid--condensed .bx--col-max-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-5,
  .bx--grid--condensed .bx--col-max-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-6,
  .bx--grid--condensed .bx--col-max-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-7,
  .bx--grid--condensed .bx--col-max-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-8,
  .bx--grid--condensed .bx--col-max-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-9,
  .bx--grid--condensed .bx--col-max-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-10,
  .bx--grid--condensed .bx--col-max-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-11,
  .bx--grid--condensed .bx--col-max-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-12,
  .bx--grid--condensed .bx--col-max-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-13 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-13,
  .bx--grid--condensed .bx--col-max-13 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-14 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-14,
  .bx--grid--condensed .bx--col-max-14 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-15 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-15,
  .bx--grid--condensed .bx--col-max-15 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-16 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-16,
  .bx--grid--condensed .bx--col-max-16 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max,
.bx--col-max--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max,
  .bx--grid--condensed .bx--col-max, .bx--row--condensed
  .bx--col-max--auto,
  .bx--grid--condensed
  .bx--col-max--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 99rem) {
  .bx--col,
  .bx--col-max {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-max--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-max-1 {
    flex: 0 0 6.25%;
    max-width: 6.25%; }
  .bx--col-max-2 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .bx--col-max-3 {
    flex: 0 0 18.75%;
    max-width: 18.75%; }
  .bx--col-max-4 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-max-5 {
    flex: 0 0 31.25%;
    max-width: 31.25%; }
  .bx--col-max-6 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .bx--col-max-7 {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .bx--col-max-8 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-max-9 {
    flex: 0 0 56.25%;
    max-width: 56.25%; }
  .bx--col-max-10 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .bx--col-max-11 {
    flex: 0 0 68.75%;
    max-width: 68.75%; }
  .bx--col-max-12 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-max-13 {
    flex: 0 0 81.25%;
    max-width: 81.25%; }
  .bx--col-max-14 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .bx--col-max-15 {
    flex: 0 0 93.75%;
    max-width: 93.75%; }
  .bx--col-max-16 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-max-0 {
    margin-left: 0; }
  .bx--offset-max-1 {
    margin-left: 6.25%; }
  .bx--offset-max-2 {
    margin-left: 12.5%; }
  .bx--offset-max-3 {
    margin-left: 18.75%; }
  .bx--offset-max-4 {
    margin-left: 25%; }
  .bx--offset-max-5 {
    margin-left: 31.25%; }
  .bx--offset-max-6 {
    margin-left: 37.5%; }
  .bx--offset-max-7 {
    margin-left: 43.75%; }
  .bx--offset-max-8 {
    margin-left: 50%; }
  .bx--offset-max-9 {
    margin-left: 56.25%; }
  .bx--offset-max-10 {
    margin-left: 62.5%; }
  .bx--offset-max-11 {
    margin-left: 68.75%; }
  .bx--offset-max-12 {
    margin-left: 75%; }
  .bx--offset-max-13 {
    margin-left: 81.25%; }
  .bx--offset-max-14 {
    margin-left: 87.5%; }
  .bx--offset-max-15 {
    margin-left: 93.75%; } }

.bx--no-gutter,
.bx--row.bx--no-gutter [class*='bx--col'] {
  padding-left: 0;
  padding-right: 0; }

.bx--no-gutter--left,
.bx--row.bx--no-gutter--left [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--right,
.bx--row.bx--no-gutter--right [class*='bx--col'] {
  padding-right: 0; }

.bx--hang--left {
  padding-left: 1rem; }

.bx--hang--right {
  padding-right: 1rem; }

.bx--aspect-ratio {
  height: 0;
  position: relative; }

.bx--aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; }

.bx--aspect-ratio--16x9 {
  padding-bottom: 56.25%; }

.bx--aspect-ratio--2x1 {
  padding-bottom: 50%; }

.bx--aspect-ratio--4x3 {
  padding-bottom: 75%; }

.bx--aspect-ratio--1x1 {
  padding-bottom: 100%; }

.bx--aspect-ratio--1x2 {
  padding-bottom: 200%; }

.masonry {
  /* Masonry container */
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  grid-column-gap: 1em;
  -webkit-column-gap: 1em;
     -moz-column-gap: 1em;
          column-gap: 1em;
  -webkit-column-count: 1;
     -moz-column-count: 1;
          column-count: 1; }
  @media (min-width: 42rem) {
    .masonry {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2; } }
  @media (min-width: 66rem) {
    .masonry {
      -webkit-column-count: 3;
         -moz-column-count: 3;
              column-count: 3; } }
  @media (min-width: 82rem) {
    .masonry {
      -webkit-column-count: 4;
         -moz-column-count: 4;
              column-count: 4; } }

@media (max-width: 20rem) {
  .bx--no-gutter--right-sm {
    padding-right: 0; } }

@media (min-width: 42rem) {
  .bx--no-gutter--right-md {
    padding-right: 0; } }

@media (min-width: 66rem) {
  .bx--no-gutter--right-lg {
    padding-right: 0; } }

@media (min-width: 82rem) {
  .bx--no-gutter--right-xlg {
    padding-right: 0; } }

@media (max-width: 20rem) {
  .bx--no-gutter--left-sm {
    padding-left: 0; } }

@media (min-width: 42rem) {
  .bx--no-gutter--left-md {
    padding-left: 0; } }

@media (min-width: 66rem) {
  .bx--no-gutter--left-lg {
    padding-left: 0; } }

@media (min-width: 82rem) {
  .bx--no-gutter--left-xlg {
    padding-left: 0; } }

.item {
  /* Masonry bricks or child elements */
  display: inline-block;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.no-margin {
  margin: 0; }

.bx--modal-container {
  font-family: "Fira Sans", sans-serif; }

.bx--modal-content > * {
  color: #171717; }

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Fira Sans", sans-serif; }

h2,
h3,
h4,
h5 {
  letter-spacing: 0.16px; }

h2 {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: #ffffff; }

h3 {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: #171717; }

p.primary {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: #171717; }

p.secondary {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: #565656; }

.bx--radio-button-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .bx--radio-button-wrapper {
      margin-bottom: 1.5rem; } }

.bx--radio-button-wrapper .bx--radio-button__label {
  font-family: "Fira Sans", sans-serif;
  color: #171717; }
  .bx--radio-button-wrapper .bx--radio-button__label span {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px; }

.bx--select,
.bx--select-input__wrapper,
.bx--select-input__wrapper select {
  width: 100%;
  max-width: 100%; }

.bx--select__arrow {
  top: 1.2rem; }

.bx--text-area {
  font-family: "Fira Sans", sans-serif;
  color: #171717; }
  .bx--text-area__wrapper {
    width: 100%; }

.bx--text-area::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Fira Sans", sans-serif;
  opacity: 1;
  /* Firefox */ }

.bx--text-area::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Fira Sans", sans-serif;
  opacity: 1;
  /* Firefox */ }

.bx--text-area:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Fira Sans", sans-serif;
  opacity: 1;
  /* Firefox */ }

.bx--text-area::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Fira Sans", sans-serif;
  opacity: 1;
  /* Firefox */ }

.bx--text-area::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Fira Sans", sans-serif;
  opacity: 1;
  /* Firefox */ }

.bx--text-area:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Fira Sans", sans-serif; }

.bx--text-area::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "Fira Sans", sans-serif; }

a.bx--header__name {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: bold;
  line-height: 1rem;
  color: #ffffff;
  max-width: 200px; }
  @media (min-width: 42rem) {
    a.bx--header__name {
      font-size: 16px;
      max-width: 100%; } }

.header-back:hover {
  box-shadow: 0 0; }

.bx--header {
  height: 72px;
  margin-bottom: 0.5rem; }
  .bx--header__menu-toggle {
    display: inline-block !important; }

.bx--side-nav--ux {
  top: 72px; }
  @media (max-width: 999rem) {
    .bx--side-nav--ux {
      width: 0; } }

.bx--side-nav:not(.bx--side-nav--fixed):hover, .bx--side-nav--expanded {
  width: 16rem; }

.bx--side-nav__link-text {
  font-family: "Fira Sans", sans-serif; }

html,
body {
  max-width: 100%;
  min-height: 100vh; }

body {
  margin: 0;
  padding: 0; }

