@import 'variables';

.bx--radio {
  &-button-wrapper {
    display: flex;
    align-items: flex-start;
    margin-bottom: $spacing-05;

    @media (min-width: map-get($breakpoints, md)) {
      margin-bottom: $spacing-06;
    }
  }

  &-button-wrapper &-button__label {
    font-family: $font-path;
    color: $text-01;

    span {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
    }
  }
}
