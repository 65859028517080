@import '../../../sass/imports/variables.scss';

.audience-information {
  margin-bottom: $spacing-05;

  .ai {
    &--title {
      margin-bottom: $spacing-05;

      @media (min-width: map-get($breakpoints, md)) {
        font-size: 16px;
        line-height: 22px;
        color: $text-01;
      }
    }

    &--icon-description {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-03;

      @media (min-width: map-get($breakpoints, md)) {
        font-size: 16px;
        line-height: 24px;
        color: $text-01;
      }

      &.inline {
        float: left;
        padding-right: $spacing-05;
        margin-bottom: $spacing-03;
      }

      & svg {
        margin-right: $spacing-02;
      }
    }
  }
}
