@import '../../../sass/imports/variables.scss';

.box-type-evidence {
  margin-bottom: $spacing-05;
  cursor: pointer;

  .bte {
    &--container {
      background-color: $ui-01;
      padding: $spacing-03;
      color: $text-01;

      &:hover {
        background-color: $hover-ui;
      }
    }

    &--title {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      margin-bottom: $spacing-03;
    }

    &--description {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      color: $text-02;

      @media (min-width: map-get($breakpoints, lg)) {
        line-height: 16px;
      }
    }
  }
}
