@import '../../../sass/imports/variables.scss';

.audience-record {
  .ar {
    &--container {
      background-color: $ui-01;
      padding: $spacing-05;
    }

    &--title {
      display: flex;
      align-items: center;
      margin-top: $spacing-01;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;

      &-primary {
        color: $link-01;
      }

      svg {
        margin-right: $spacing-03;
      }
    }

    &--description {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.32px;
      margin-top: $spacing-03;
      color: $text-02;
    }

    &--align-items-center {
      display: flex;
      align-items: center;
    }
  }

  &.ar--spacing {
    &-03 {
      padding-bottom: $spacing-03;
    }

    &-05 {
      padding-bottom: $spacing-05;
    }
  }

  &.stealthy {
    svg path {
      fill: $text-01;
    }
  }
}
