@import '../../../sass/imports/variables.scss';

.requests-processed {
  &-container {
    overflow-y: auto;

    .bx--grid {
      width: calc(100vw * 3);

      @media (min-width: map-get($breakpoints, md)) {
        width: 100%;
      }
    }

    .bx--btn--request {
      margin-bottom: $spacing-05;
    }
  }
}
