@import 'variables';


a.bx--header__name {
  font-family: $font-secondary;
  font-size: 12px;
  font-weight: bold;
  line-height: $spacing-05;
  color: $text-04;
  max-width: 200px;

  @media (min-width: map-get($breakpoints, md)) {
    font-size: 16px;
    max-width: 100%;
  }
}

.header-back {
  &:hover {
    box-shadow: 0 0;
  }
}

.bx--header {
  height: 72px;
  margin-bottom: $spacing-03;

  &__menu-toggle {
    display: inline-block !important;
  }
}

.bx--side-nav {
  &--ux {
    top: 72px;

    @media (max-width: 999rem) {
      width: 0;
    }
  }

  &:not(.bx--side-nav--fixed):hover,
  &--expanded {
    width: 16rem;
  }

  &__link-text {
    font-family: $font-path;
  }
}
