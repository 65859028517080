@import 'variables';

.bx--label,
.bx--form__helper-text,
.bx--text-input,
.bx--form-item,
.bx--select-input,
.bx--checkbox-label,
.bx--inline-loading__text,
.bx--form-requirement {
  font-family: $font-path;
}

.bx--text-input__field-wrapper {
  width: 100%;
}

.bx--fieldset {
  margin-bottom: $spacing-05;

  @media (min-width: map-get($breakpoints, md)) {
    margin-bottom: $spacing-06;
  }
}

.bx--inline-loading {
  padding-top: $spacing-03;
  padding-bottom: $spacing-03;

  @media (min-width: map-get($breakpoints, md)) {
    justify-content: center;
  }
}

.bx--inline-notification {
  @media (max-width: map-get($breakpoints, md)) {
    max-width: map-get($breakpoints, md);
  }
}

.bx--form-item.bx--checkbox-wrapper {
  margin-bottom: $spacing-05;

  @media (min-width: map-get($breakpoints, md)) {
    margin-bottom: $spacing-06;
  }
}

.bx--modal-content {
  width: 100%;
  padding-right: 1rem;
}
