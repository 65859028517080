@import 'variables';

.bx--link,
.bx--btn {
  font-family: $font-path;
}

.bx--btn {
  &--gray {
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-color: $ui-04;
    color: $ui-04;


    svg path {
      fill: $ui-04 !important;
    }

    &:hover {
      color: #ffffff;
      background-color: $ui-04;

      svg path {
        fill: #ffffff !important;
      }
    }

    &:active {
      color: #ffffff;
      background-color: $ui-05;
    }

    &:focus::after {
      border-color: $ui-05;
    }
  }

  &--disabled {
    svg path {
      fill: $ui-04 !important;
    }
  }

  &--container {
    &-center {
      display: flex;
      justify-content: center;
    }
  }

  &--full-with {
    @media (max-width: map-get($breakpoints, md)) {
      max-width: 100%;
      width: 100%;
    }
  }
}
