@import url('https://fonts.googleapis.com/css?family=Montserrat:700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap');

@import 'variables';

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: $font-path;
}

h2,
h3,
h4,
h5 {
  letter-spacing: 0.16px;
}

h2 {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  color: $text-04;
}

h3 {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0.32px;
  color: $text-01;
}

// h4 {
// }

// h5 {
// }

p.primary {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: $text-01;
}

p.secondary {
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  color: $text-02;
}
