@import '../../../../sass/imports/variables.scss';

.header-inline-container {
  .hi {
    &--body {
      background-color: $ui-05;
      padding: $spacing-05;

      &-content {
        display: flex;
        align-items: center;

        &-back {
          float: left;
          height: 32px;
          margin-right: $spacing-05;
        }
      }
    }

    &--subtitle {
      display: flex;
      align-items: center;
      padding-left: $spacing-03;
      padding-right: $spacing-03;
      background-color: $ui-background;
      margin-top: -$spacing-03;
      margin-left: $spacing-05;
      margin-right: $spacing-05;
      width: 144px;
      height: 40px;
    }

  }
}
