@import '../../../sass/imports/variables.scss';

.detail-request {
  .dr {
    &--info {
      &-container {
        margin-top: $spacing-06;
        margin-bottom: $spacing-05;

        @media (min-width: map-get($breakpoints, lg)) {
          margin-top: $spacing-07;
          margin-bottom: $spacing-07;
        }
      }

      &-text {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.32px;

        &-desc {
          color: $text-02;
          margin-bottom: $spacing-01;
        }

        &-name {
          color: $text-01;
        }
      }

      &-like {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.32px;
      }
    }

    &--input {
      margin-top: $spacing-05;
      margin-bottom: $spacing-07;
    }
  }
}