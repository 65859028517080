@import '../../../sass/imports/variables.scss';

.sign-up {
  margin-bottom: $spacing-09;

  .su {
    h2 {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
    }

    h2.title {
      margin-top: $spacing-07;
      margin-bottom: $spacing-07;
    }

    h2.register {
      margin-bottom: $spacing-05;
    }

    h3 {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: $text-02;
      margin-bottom: $spacing-05;
    }

    &--svg-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    p.description {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.16px;
      margin-bottom: $spacing-05;
    }

    &--sign-in-btn {
      margin-top: $spacing-03;
      margin-bottom: $spacing-07;
    }

    &--btn-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $spacing-09;
      width: $spacing-09;
      border: 2px solid transparent;
      overflow: hidden;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);

      &:hover {
        background: $hover-ui;
      }
    }

    &--checkbox {
      margin-bottom: $spacing-05;
    }
  }
}
