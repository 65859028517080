@import '../../../node_modules/carbon-components/scss/globals/scss/vars';

// Font
$font-primary: 'Fira Sans';
$font-secondary: 'Montserrat';
$font-path: $font-primary, sans-serif;

// Colors
$ui-05: #313344;

// Breakpoints
@function carbon--rem($px) {
  @return ($px / $carbon--base-font-size) * 1rem;
}

$breakpoints: (
  sm: carbon--rem(320px),
  md: carbon--rem(672px),
  lg: carbon--rem(1056px),
  xlg: carbon--rem(1312px),
  max: carbon--rem(1584px)
) !default;
