@import '../../../sass/imports/variables.scss';

.audience {
  &-container {
    margin-bottom: $spacing-05;

    @media (min-width: map-get($breakpoints, md)) {
      margin-bottom: $spacing-07;
    }
  }

  &-header {
    background-color: $ui-05;
    padding-top: $spacing-05;
    padding-left: $spacing-05;
    padding-right: $spacing-05;
    padding-bottom: $spacing-08;

    @media (min-width: map-get($breakpoints, md)) {
      padding-top: $spacing-07;
      padding-left: $spacing-07;
      padding-right: $spacing-07;
    }

    h2 {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &-body {
    background-color: $ui-01;
    padding: $spacing-05;

    @media (min-width: map-get($breakpoints, md)) {
      padding: $spacing-07;
    }

    & .owner {
      display: flex;
      align-items: center;
      margin-top: -$spacing-09;
      margin-bottom: $spacing-03;

      h3 {
        display: flex;
        align-items: center;
        padding-left: $spacing-03;
        padding-right: $spacing-03;
        background-color: $ui-background;
        height: 40px;

        @media (min-width: map-get($breakpoints, md)) {
          margin-bottom: $spacing-05;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    & p.description {
      margin-bottom: $spacing-05;

      @media (min-width: map-get($breakpoints, md)) {
        margin-bottom: $spacing-07;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
