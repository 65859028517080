@import '../../../sass/imports/variables.scss';

.box-button {
  .bb {
    &--container {
      background-color: $ui-01;
      padding: $spacing-05;
      margin-bottom: $spacing-05;
      cursor: pointer;

      &:hover {
        background-color: $hover-ui;
      }
    }

    &--labelText {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: $ui-05;
    }
  }

  &--checked {
    .bb {
      &--container {
        background-color: $link-01;

        &:hover {
          background-color: $hover-primary;
        }
      }

      &--labelText {
        color: #FFF;
      }
    }
  }
}
