@import '../../../sass/imports/variables.scss';

.box-link {
  .bl {
    &--container {
      background-color: $ui-01;
      padding: $spacing-05;
      margin-bottom: $spacing-05;
      cursor: pointer;

      &:hover {
        background-color: $hover-ui;
      }
    }

    &--title {
      font-size: 16px;
      line-height: 24px;
      color: $link-01;
    }

    &--description {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      color: $text-02;
    }
  }

  .blf {
    &--container {
      background-color: $ui-01;
      padding: $spacing-05;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: $spacing-03;
    }

    &--container-label,
    &--container-remove {
      display: flex;
      align-items: center;
    }

    &--container-remove {
      justify-content: center;
      line-height: 1;

      a:hover {
        box-shadow: none;
      }
    }

    &--title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: $spacing-03;
      color: $text-01;
    }

    &--labelText {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: $text-01;
    }

    &--labelSubText {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      color: $text-02;
    }

    &--link {
      margin-bottom: $spacing-07;
    }
  }
}
