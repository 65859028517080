@import '../../../../sass/imports/variables.scss';

.header {
  &-container {
    margin-bottom: $spacing-03;
  }

  &-body {
    width: 100%;
    background-color: $ui-05;
    padding-top: $spacing-05;
    padding-bottom: $spacing-05;
  }

  &-back {
    margin-left: -9px !important;
  }

  &-title {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  &-subtitle {
    display: flex;
    align-items: center;
    margin-top: -$spacing-03;
    margin-bottom: $spacing-03;

    h3 {
      display: flex;
      align-items: center;
      padding-left: $spacing-03;
      padding-right: $spacing-03;
      background-color: $ui-background;
      height: 40px;

      @media (min-width: map-get($breakpoints, md)) {
        margin-bottom: $spacing-05;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
