@import './imports/carbon';
@import './imports/accordion';
@import './imports/buttons';
@import './imports/fileUploaderButton';
@import './imports/forms';
@import './imports/grid';
@import './imports/modal';
@import './imports/paragraphs';
@import './imports/radioButton';
@import './imports/select';
@import './imports/textArea';
@import './imports/ui';

html,
body {
  max-width: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

// .sticky {
//   position: -webkit-sticky; // required for Safari and ios
//   position: sticky;
//   top: 15px;
//   padding-bottom: 50px;
// }
