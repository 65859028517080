@import '../../../sass/imports/variables.scss';

.audience-vote {
  margin-bottom: $spacing-09;
  overflow-y: auto;

  .bx--grid {
    width: calc(100vw * 3);

    @media (min-width: map-get($breakpoints, md)) {
      width: 100%;
    }
  }

  h3,
  h4 {
    font-size: 14px;
    line-height: 18px;
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  h3.question {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
    }
  }

  h4.order-type {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 28px;
      line-height: 36px;
      color: $text-01;
    }

    span {
      &.yes {
        color: #127373;
      }

      &.no {
        color: #AD3062;
      }

      &.others {
        color: #6E32C9;
      }
    }
  }

  .av {
    &--box-result {
      display: flex;
      align-items: center;
      margin-bottom: $spacing-05;

      font-size: 16px;
      line-height: 22px;

      color: $support-02;
    }

    &--send {
      margin-top: $spacing-05;
    }

    &--bar-progress {
      width: 100%;
      margin-bottom: $spacing-08;

      &-yes {
        background-color: #92EEEE;
        width: 60%;
        height: 16px;
        float: left;
      }

      &-no {
        background-color: #FFCFE1;
        width: 30%;
        height: 16px;
        float: left;
      }

      &-others {
        background-color: #6E32C9;
        width: 10%;
        height: 16px;
        float: left;
      }
    }
  }

  .container-label {
    margin-bottom: $spacing-05;

    .label-status {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.32px;
      padding: 4px 8px;
      border-radius: 24px;

      &--no {
        color: #A11950;
        background-color: #FFCFE1;
      }

      &--others {
        color: #6E32C9;
        background-color: #E6D6FF
      }

      &--yes {
        color: #006161;
        background-color: #92EEEE;
      }
    }
  }
}
