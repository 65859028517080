@import 'variables';

.bx--text-area {
  font-family: $font-path;
  color: $text-01;

  &__wrapper {
    width: 100%;
  }
}

.bx--text-area::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: $font-path;
  opacity: 1; /* Firefox */
}

.bx--text-area:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: $font-path;
}

.bx--text-area::-ms-input-placeholder { /* Microsoft Edge */
  font-family: $font-path;
}