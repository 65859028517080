@import '../../../sass/imports/variables.scss';

.audience-requests {

  h3,
  h4 {
    font-size: 14px;
    line-height: 18px;
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  h3.question {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 20px;
      line-height: 26px;
      color: $text-01;
    }
  }

  h4.order-type {
    @media (min-width: map-get($breakpoints, md)) {
      font-size: 28px;
      line-height: 36px;
      color: $text-01;
    }
  }
}
