@import 'variables';

.bx--modal {
  &-container {
    font-family: $font-path;
  }

  &-content > * {
    color: $text-01;
  }
}